import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_APPROVALS = 'DOWNLOADING_APPROVALS';
export const downloadingApprovals = makeActionCreator(DOWNLOADING_APPROVALS, 'status');

export const SET_APPROVALS = 'SET_APPROVALS';
export const setApprovals = makeActionCreator(SET_APPROVALS, 'data');

export const ADD_APPROVAL = 'ADD_APPROVAL';
export const addApproval = makeActionCreator(ADD_APPROVAL, 'data');

export const CLEAR_APPROVALS = 'CLEAR_APPROVALS';
export const clearApprovals = makeActionCreator(CLEAR_APPROVALS);

export const DOWNLOADING_APPROVAL_DETAIL = 'DOWNLOADING_APPROVAL_DETAIL';
export const downloadingApprovalDetail = makeActionCreator(DOWNLOADING_APPROVAL_DETAIL, 'status');

export const DOWNLOADING_APPROVAL_ATTACHMENT = 'DOWNLOADING_APPROVAL_ATTACHMENT';
export const downloadingApprovalAttachment = makeActionCreator(DOWNLOADING_APPROVAL_ATTACHMENT, 'status');


export const SET_APPROVAL_SELECTED_PAGE_SIZE = 'SET_APPROVAL_SELECTED_PAGE_SIZE';
export const setApprovalSelectedPageSize = makeActionCreator(SET_APPROVAL_SELECTED_PAGE_SIZE, 'size');

export const SET_APPROVAL_SELECTED_ORDER_BY = 'SET_APPROVAL_SELECTED_ORDER_BY';
export const setApprovalSelectedOrderBy = makeActionCreator(SET_APPROVAL_SELECTED_ORDER_BY, 'order');

export const SET_APPROVAL_TAPPED_ID = 'SET_APPROVAL_TAPPED_ID';
export const setApprovalTappedId = makeActionCreator(SET_APPROVAL_TAPPED_ID, 'id');

export const SET_APPROVAL_SEARCH_TEXT = 'SET_APPROVAL_SEARCH_TEXT';
export const setApprovalSearchText = makeActionCreator(SET_APPROVAL_SEARCH_TEXT, 'text');

export const SET_APPROVAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_APPROVAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setApprovalAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_APPROVAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SHOW_APPROVAL_APPROVER_POP_UP = 'SHOW_APPROVAL_APPROVER_POP_UP';
export const showApprovalApproverPopUp = makeActionCreator(SHOW_APPROVAL_APPROVER_POP_UP, 'status');

export const SHOW_APPROVAL_COMMENT_POP_UP = 'SHOW_APPROVAL_COMMENT_POP_UP';
export const showApprovalCommentPopUp = makeActionCreator(SHOW_APPROVAL_COMMENT_POP_UP, 'status');


export const DOWNLOADING_APPROVAL_PROCESSES = 'DOWNLOADING_APPROVAL_PROCESSES';
export const downloadingApprovalProcesses = makeActionCreator(DOWNLOADING_APPROVAL_PROCESSES, 'status');

export const DOWNLOADING_APPROVAL_PROVIDERS = 'DOWNLOADING_APPROVAL_PROVIDERS';
export const downloadingApprovalProviders = makeActionCreator(DOWNLOADING_APPROVAL_PROVIDERS, 'status');

export const SET_APPROVAL_PROCESSES = 'SET_APPROVAL_PROCESSES';
export const setApprovalProcesses = makeActionCreator(SET_APPROVAL_PROCESSES, 'data');

export const SET_APPROVAL_PROVIDERS = 'SET_APPROVAL_PROVIDERS';
export const setApprovalProviders = makeActionCreator(SET_APPROVAL_PROVIDERS, 'data');

export const CLEAR_APPROVAL_PROCESSES = 'CLEAR_APPROVAL_PROCESSES';
export const clearApprovalProcesses = makeActionCreator(CLEAR_APPROVAL_PROCESSES);

export const CLEAR_APPROVAL_PROVIDERS = 'CLEAR_APPROVAL_PROVIDERS';
export const clearApprovalProviders = makeActionCreator(CLEAR_APPROVAL_PROVIDERS);

export const SET_APPROVAL_SELECTED_STEP_ID = 'SET_APPROVAL_SELECTED_STEP_ID';
export const setApprovalSelectedStepId = makeActionCreator(SET_APPROVAL_SELECTED_STEP_ID, 'id');

export const RESENDING_EVENT = 'RESENDING_EVENT';
export const resendingEvent = makeActionCreator(RESENDING_EVENT, 'status');

export const SET_APPROVAL_SELECTED_RESEND_EVENT_OPTION = 'SET_APPROVAL_SELECTED_RESEND_EVENT_OPTION';
export const setApprovalSelectedResendEventOption = makeActionCreator(SET_APPROVAL_SELECTED_RESEND_EVENT_OPTION, 'value');


export const SET_APPROVAL_GROUPS = 'SET_APPROVAL_GROUPS';
export const setApprovalGroups = makeActionCreator(SET_APPROVAL_GROUPS, 'data');

export const ADD_APPROVAL_GROUP = 'ADD_APPROVAL_GROUP';
export const addApprovalGroup = makeActionCreator(ADD_APPROVAL_GROUP, 'data');

export const CLEAR_APPROVAL_GROUPS = 'CLEAR_APPROVAL_GROUPS';
export const clearApprovalGroups = makeActionCreator(CLEAR_APPROVAL_GROUPS);

export const DOWNLOADING_APPROVAL_GROUPS = 'DOWNLOADING_APPROVAL_GROUPS';
export const downloadingApprovalGroups = makeActionCreator(DOWNLOADING_APPROVAL_GROUPS, 'status');

export const SET_APPROVAL_GROUP_SELECTED_PAGE_SIZE = 'SET_APPROVAL_GROUP_SELECTED_PAGE_SIZE';
export const setApprovalGroupSelectedPageSize = makeActionCreator(SET_APPROVAL_GROUP_SELECTED_PAGE_SIZE, 'size');

export const SET_APPROVAL_GROUP_SELECTED_ORDER_BY = 'SET_APPROVAL_GROUP_SELECTED_ORDER_BY';
export const setApprovalGroupSelectedOrderBy = makeActionCreator(SET_APPROVAL_GROUP_SELECTED_ORDER_BY, 'order');

export const SET_APPROVAL_GROUP_TAPPED_ID = 'SET_APPROVAL_GROUP_TAPPED_ID';
export const setApprovalGroupTappedId = makeActionCreator(SET_APPROVAL_GROUP_TAPPED_ID, 'id');

export const SET_APPROVAL_GROUP_SEARCH_TEXT = 'SET_APPROVAL_GROUP_SEARCH_TEXT';
export const setApprovalGroupSearchText = makeActionCreator(SET_APPROVAL_GROUP_SEARCH_TEXT, 'text');

export const SET_APPROVAL_GROUP_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_APPROVAL_GROUP_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setApprovalGroupAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_APPROVAL_GROUP_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_APPROVAL_GROUP_TABLE_DATA_CUSTOMER_MEMBERS = 'SET_APPROVAL_GROUP_TABLE_DATA_CUSTOMER_MEMBERS';
export const setApprovalGroupTableDataCustomerMembers = makeActionCreator(SET_APPROVAL_GROUP_TABLE_DATA_CUSTOMER_MEMBERS, 'option');

export const SET_APPROVAL_GROUP_SELECTED_MEMBER_GROUP = 'SET_APPROVAL_GROUP_SELECTED_MEMBER_GROUP';
export const setApprovalGroupSelectedMemberGroup = makeActionCreator(SET_APPROVAL_GROUP_SELECTED_MEMBER_GROUP, 'option');

export const ADDING_APPROVAL_GROUP_MEMBER_GROUP = 'ADDING_APPROVAL_GROUP_MEMBER_GROUP';
export const addingApprovalGroupMemberGroup = makeActionCreator(ADDING_APPROVAL_GROUP_MEMBER_GROUP, 'status');

export const ADDING_EDITING_APPROVAL_GROUP = 'ADDING_EDITING_APPROVAL_GROUP';
export const addingEditingApprovalGroup = makeActionCreator(ADDING_EDITING_APPROVAL_GROUP, 'status');

export const DOWNLOADING_DELETING_APPROVAL_GROUP = 'DOWNLOADING_DELETING_APPROVAL_GROUP';
export const downloadingDeletingApprovalGroup = makeActionCreator(DOWNLOADING_DELETING_APPROVAL_GROUP, 'status');

export const ENABLING_DISABLING_APPROVAL_GROUP = 'ENABLING_DISABLING_APPROVAL_GROUP';
export const enablingDisablingApprovalGroup = makeActionCreator(ENABLING_DISABLING_APPROVAL_GROUP, 'status');


export const SET_FLOW_DESIGNERS = 'SET_FLOW_DESIGNERS';
export const setFlowDesigners = makeActionCreator(SET_FLOW_DESIGNERS, 'data');

export const ADD_FLOW_DESIGNER = 'ADD_FLOW_DESIGNER';
export const addFlowDesigner = makeActionCreator(ADD_FLOW_DESIGNER, 'data');

export const CLEAR_FLOW_DESIGNERS = 'CLEAR_FLOW_DESIGNERS';
export const clearFlowDesigners = makeActionCreator(CLEAR_FLOW_DESIGNERS);

export const DOWNLOADING_FLOW_DESIGNERS = 'DOWNLOADING_FLOW_DESIGNERS';
export const downloadingFlowDesigners = makeActionCreator(DOWNLOADING_FLOW_DESIGNERS, 'status');

export const SET_FLOW_DESIGNER_SELECTED_PAGE_SIZE = 'SET_FLOW_DESIGNER_SELECTED_PAGE_SIZE';
export const setFlowDesignerSelectedPageSize = makeActionCreator(SET_FLOW_DESIGNER_SELECTED_PAGE_SIZE, 'size');

export const SET_FLOW_DESIGNER_SELECTED_ORDER_BY = 'SET_FLOW_DESIGNER_SELECTED_ORDER_BY';
export const setFlowDesignerSelectedOrderBy = makeActionCreator(SET_FLOW_DESIGNER_SELECTED_ORDER_BY, 'order');

export const SET_FLOW_DESIGNER_TAPPED_ID = 'SET_FLOW_DESIGNER_TAPPED_ID';
export const setFlowDesignerTappedId = makeActionCreator(SET_FLOW_DESIGNER_TAPPED_ID, 'id');

export const SET_FLOW_DESIGNER_SEARCH_TEXT = 'SET_FLOW_DESIGNER_SEARCH_TEXT';
export const setFlowDesignerSearchText = makeActionCreator(SET_FLOW_DESIGNER_SEARCH_TEXT, 'text');

export const SET_FLOW_DESIGNER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_FLOW_DESIGNER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setFlowDesignerAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_FLOW_DESIGNER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_FLOW_DESIGNER_SELECTED_TYPE = 'SET_FLOW_DESIGNER_SELECTED_TYPE';
export const setFlowDesignerSelectedType = makeActionCreator(SET_FLOW_DESIGNER_SELECTED_TYPE, 'option');

export const ADDING_EDITING_FLOW_DESIGNER = 'ADDING_EDITING_FLOW_DESIGNER';
export const addingEditingFlowDesigner = makeActionCreator(ADDING_EDITING_FLOW_DESIGNER, 'status');

export const DOWNLOADING_DELETING_FLOW_DESIGNER = 'DOWNLOADING_DELETING_FLOW_DESIGNER';
export const downloadingDeletingFlowDesigner = makeActionCreator(DOWNLOADING_DELETING_FLOW_DESIGNER, 'status');

export const SET_FLOW_DESIGNER_APPROVERS = 'SET_FLOW_DESIGNER_APPROVERS';
export const setFlowDesignerApprovers = makeActionCreator(SET_FLOW_DESIGNER_APPROVERS, 'approver');

export const CLEAR_FLOW_DESIGNER_APPROVERS = 'CLEAR_FLOW_DESIGNER_APPROVERS';
export const clearFlowDesignerApprovers = makeActionCreator(CLEAR_FLOW_DESIGNER_APPROVERS);

export const SET_FLOW_DESIGNER_SELECTED_APPROVER_MODE = 'SET_FLOW_DESIGNER_SELECTED_APPROVER_MODE';
export const setFlowDesignerSelectedApproverMode = makeActionCreator(SET_FLOW_DESIGNER_SELECTED_APPROVER_MODE, 'option');

export const SET_FLOW_DESIGNER_APPROVERS_DIALOG_VISIBILITY = 'SET_FLOW_DESIGNER_APPROVERS_DIALOG_VISIBILITY';
export const setFlowDesignerApproversDialogVisibility = makeActionCreator(SET_FLOW_DESIGNER_APPROVERS_DIALOG_VISIBILITY, 'option');

export const SET_FLOW_DESIGNER_ADDING_APPROVERS = 'SET_FLOW_DESIGNER_ADDING_APPROVERS';
export const setFlowDesignerAddingApprovers = makeActionCreator(SET_FLOW_DESIGNER_ADDING_APPROVERS, 'option');

export const SET_FLOW_DESIGNER_SELECTED_APPROVER_INDEX = 'SET_FLOW_DESIGNER_SELECTED_APPROVER_INDEX';
export const setFlowDesignerSelectedApproverIndex = makeActionCreator(SET_FLOW_DESIGNER_SELECTED_APPROVER_INDEX, 'option');

export const SET_FLOW_DESIGNER_SELECTED_PROCESS_FLOW_INDEX = 'SET_FLOW_DESIGNER_SELECTED_PROCESS_FLOW_INDEX';
export const setFlowDesignerSelectedProcessFlowIndex = makeActionCreator(SET_FLOW_DESIGNER_SELECTED_PROCESS_FLOW_INDEX, 'index');


export const SET_ADDING_APPROVER_TABLE = 'SET_ADDING_APPROVER_TABLE';
export const setAddingApproverTable = makeActionCreator(SET_ADDING_APPROVER_TABLE, 'status');

export const SET_APPROVER_TABLE_DATA = 'SET_APPROVER_TABLE_DATA';
export const setApproverTableData = makeActionCreator(SET_APPROVER_TABLE_DATA, 'data');

export const ADD_APPROVER_TABLE_DATA = 'ADD_APPROVER_TABLE_DATA';
export const addApproverTableData = makeActionCreator(ADD_APPROVER_TABLE_DATA, 'data');

export const CLEAR_APPROVER_TABLE_DATA = 'CLEAR_APPROVER_TABLE_DATA';
export const clearApproverTableData = makeActionCreator(CLEAR_APPROVER_TABLE_DATA);

export const SET_APPROVER_TABLE_SELECTED_STEP_ID = 'SET_APPROVER_TABLE_SELECTED_STEP_ID';
export const setApproverTableSelectedStepId = makeActionCreator(SET_APPROVER_TABLE_SELECTED_STEP_ID, 'index');

export const SET_APPROVER_TABLE_SELECTED_TABLE_DATA_ID = 'SET_APPROVER_TABLE_SELECTED_TABLE_DATA_ID';
export const setApproverTableSelectedTableDataId = makeActionCreator(SET_APPROVER_TABLE_SELECTED_TABLE_DATA_ID, 'index');

export const SET_APPROVER_TABLE_SELECTED_TYPE = 'SET_APPROVER_TABLE_SELECTED_TYPE';
export const setApproverTableSelectedType = makeActionCreator(SET_APPROVER_TABLE_SELECTED_TYPE, 'option');

export const SET_APPROVER_TABLE_SELECTED_USER = 'SET_APPROVER_TABLE_SELECTED_USER';
export const setApproverTableSelectedUser = makeActionCreator(SET_APPROVER_TABLE_SELECTED_USER, 'option');

export const SET_APPROVER_TABLE_SELECTED_GROUP_NAME = 'SET_APPROVER_TABLE_SELECTED_GROUP_NAME';
export const setApproverTableSelectedGroupName = makeActionCreator(SET_APPROVER_TABLE_SELECTED_GROUP_NAME, 'option');

export const SET_APPROVER_TABLE_SELECTED_GROUP_MODE = 'SET_APPROVER_TABLE_SELECTED_GROUP_MODE';
export const setApproverTableSelectedGroupMode = makeActionCreator(SET_APPROVER_TABLE_SELECTED_GROUP_MODE, 'option');

export const SET_APPROVER_TABLE_SELECTED_LEVEL = 'SET_APPROVER_TABLE_SELECTED_LEVEL';
export const setApproverTableSelectedLevel = makeActionCreator(SET_APPROVER_TABLE_SELECTED_LEVEL, 'option');
