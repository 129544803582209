import React, {
  useState, useEffect, forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import {
  Badge, createMuiTheme, FormControl, FormHelperText, IconButton, makeStyles, Menu, MenuItem, Paper,
  ThemeProvider,
} from '@material-ui/core';
import {
  AddCircleOutlineRounded, ArrowDropDown, ArrowUpward, Clear, MoreHoriz, Search, SkipNextOutlined,
  SkipPreviousOutlined,
} from '@material-ui/icons';
import { CircleEditOutline } from 'mdi-material-ui';
import {
  COLOR_BACKGROUND, COLOR_BODY_TEXT, COLOR_DANGEROUS, COLOR_DISABLED_ROW, COLOR_ICON_LIGHTER,
  COLOR_PRIMARY, COLOR_SECONDARY, COLOR_TERTIARY, PAGE_SIZE_OPTIONS, STATUS_DISABLED,
} from '../../constant';
import { isEvenNumber, onSortPress } from '../../helper';
import LocalizedString from '../../localization';
import { TableColumnShape } from '../../type';
import AccentButton from '../accent-button';
// eslint-disable-next-line import/no-cycle
import AdvancedFilterDialog from './advanced-filter-dialog';

const deleteIcon = require('../../asset/delete.png');
const filterIcon = require('../../asset/filter.png');

const ITEM_HEIGHT = 48;

const newStyles = {
  tableBaseIcon: { color: COLOR_ICON_LIGHTER },
  tableIcon: { fontSize: '20px', color: COLOR_ICON_LIGHTER },
  tableSortIcon: { fontSize: '12px', color: COLOR_ICON_LIGHTER },
  primaryIcon: { color: COLOR_PRIMARY },
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
    width: '100%',
  },
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
  greenButton: {
    color: COLOR_PRIMARY,
  },
  redButton: {
    color: COLOR_DANGEROUS,
  },
  filterButtonContainer: {
    marginRight: '20px',
  },
  badge: {
    margin: theme.spacing(0.5),
    width: 10,
  },
  moreButton: {
    padding: '0px',
  },
  buttonOutline: {
    fontSize: '12px',
    padding: '0px 2px',
  },
  filterActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '-45px',
    padding: '6px 0px 10px 20px',
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: '14px',
      },
    },
  },
});

const addedData = (customLookUpField, newData) => (customLookUpField
  ? newData[customLookUpField] : newData);

const isDuplicatedData = (data, newData, customValidationField, oldData) => {
  const isValidField = (customValidationField
    ? !!Object.prototype.hasOwnProperty.call(newData, customValidationField)
    : !!(data.every((x) => Object.prototype.hasOwnProperty.call(x, 'label') || Object.prototype.hasOwnProperty.call(x, 'value')) && Object.prototype.hasOwnProperty.call(newData, 'value')));

  if (isValidField) {
    if (customValidationField) {
      if (oldData) {
        const oldDataId = data.findIndex((x) => x[customValidationField]
        === oldData[customValidationField]);
        const newDataId = data.findIndex((x) => x[customValidationField]
        === newData[customValidationField]);

        if (oldDataId === newDataId) { return false; }
        return !!data.find((x) => x[customValidationField] === newData[customValidationField]);
      }
      return !!data.find((x) => x[customValidationField] === newData[customValidationField]);
    }
    return !!data.find((x) => x.value.toUpperCase() === newData.value.toUpperCase());
  }
  return false;
};

const isEmptyRequiredField = (newData, requiredTableColumnField, optionalTableColumnField) => {
  if (requiredTableColumnField.length > 0) {
    if (Object.keys(newData).length > 0) {
      const checkRequired = requiredTableColumnField
        .every((x) => Object.prototype.hasOwnProperty.call(newData, x) && newData[x] !== '');

      if (optionalTableColumnField.length > 0) {
        const checkOptional = optionalTableColumnField
          .some((x) => Object.prototype.hasOwnProperty.call(newData, x) && newData[x] !== '');

        return !(checkOptional && checkRequired);
      }
      return !checkRequired;
    }
    return true;
  }
  return false;
};

const isErrorLink = (newData) => {
  const matchLink = (url) => {
    const regexYoutubeWatch = /^https?:\/\/(www\.)?youtube\.com\/watch\?(.+&)?v=([a-zA-Z0-9_-]+)/;
    const regexSimpleYoutube = /^https?:\/\/(www\.)?youtu\.be\/([a-zA-Z0-9_-]+)$/;
    const regexYoutubeEmbed = /^https?:\/\/(www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]+)$/;

    return !(url.match(regexYoutubeWatch) || url.match(regexSimpleYoutube)
    || url.match(regexYoutubeEmbed));
  };

  if (Object.prototype.hasOwnProperty.call(newData, 'link')) {
    if (typeof newData.link === 'string') { return matchLink(newData.link); }
    return matchLink(newData.link.path);
  }
  return false;
};

const EditableTableField = ({
  addMenuList, defaultValue, filterColumns, filterSections, moreMenuList, optionalTableColumnField,
  requiredTableColumnField, tableColumns,
  disableActions, disabled, disableDelete, disableEdit, disableToolbar, error, hidden, loading,
  paging, search, selection, useTwoColumnFilterDialog,
  onAddPressed, onAdvancedFilterPressed, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onChangePage, onChangePageSize, onDeletePressed, onMorePressed,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSelectionChange, onSortPressed,
  actionsColumnIndex, currentPage, selectedPageSize, totalCount, rowStyle,
  addButtonCaption, customDeleteButtonCaption, customDeleteConfirmationMessage, customLookUpField,
  customValidationField, filterString, label, helperText, orderBy, searchBarText, tableLayout,
  onEditPressed,
  ...props
}) => {
  const [initSearchText, setInitSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [advancedFilterVisibility, setAdvancedFilterVisibility] = useState(false);

  const classes = useStyles();

  const initialState = {
    mouseX: null,
    mouseY: null,
  };
  const [state, setState] = useState(initialState);
  const [data, setData] = useState(defaultValue);
  const [errorUrl, setErrorUrl] = useState(false);
  const [errorDuplicateData, setErrorDuplicateData] = useState(false);
  const [errorRequiredField, setErrorRequiredField] = useState(false);

  useEffect(() => {
    if (!loading) {
      const transformedData = defaultValue.map((x) => ({
        ...x,
        tableData: {
          ...x.tableData,
          checked: (selectedRows.some((y) => y?.id === x?.id)) || false,
        },
      }));
      setData(transformedData);
    }
  }, [loading, defaultValue, totalCount, data.length, selectedRows]);

  useEffect(() => {
    if (searchBarText) {
      setInitSearchText(encodeURIComponent(searchBarText));
    } else {
      setInitSearchText('');
    }
  }, [searchBarText]);

  const onCloseMoreMenu = () => {
    setState(initialState);
  };

  const onOpenMoreMenu = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const onSubMoreMenuPress = (itemFunction) => {
    itemFunction();
    onCloseMoreMenu();
  };

  const renderMoreMenu = () => (moreMenuList.some((x) => !x.disabled
  && x.caption !== LocalizedString.common.labelCancel)) && (
  <div>
    <IconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      size="small"
      onClick={(event) => {
        onOpenMoreMenu(event);
      }}
      className={classes.moreButton}
    >
      <MoreHoriz style={newStyles.primaryIcon} fontSize="small" />
    </IconButton>

    <Menu
      keepMounted
      open={state.mouseY !== null}
      onClose={onCloseMoreMenu}
      anchorReference="anchorPosition"
      anchorPosition={state.mouseY !== null && state.mouseX !== null
        ? { top: state.mouseY, left: state.mouseX }
        : undefined}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: 200,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      {moreMenuList.map((item) => {
        if (!item.disabled && item.caption !== LocalizedString.common.labelCancel) {
          return (
            <MenuItem key={item.caption} onClick={() => onSubMoreMenuPress(item.onPress)}>
              {item.caption}
            </MenuItem>
          );
        }
        return null;
      })}
    </Menu>
  </div>
  );

  const errorLinkField = errorUrl ? LocalizedString.common.errMsgInvalidYoutubeUrl
    : helperText;
  const errorReqField = errorRequiredField ? LocalizedString.common.errMsgEmptyRequiredFields
    : errorLinkField;
  const errorText = errorDuplicateData ? LocalizedString.common.errMsgDuplicatedData
    : errorReqField;

  let actions = [];
  if (disableActions) {
    actions = [];
  } else {
    actions = [
      {
        position: 'row',
        iconProps: { fontSize: 'small' },
        action: (rowData) => ({
          icon: () => renderMoreMenu(),
          hidden: rowData.hideContextMenu || false,
          onClick: (event, rowdata) => onMorePressed(rowdata.id),
          position: 'row',
        }),
      },
    ];
  }

  const rowPadding = disableActions ? '4px 16px' : '0px 16px';

  if (!hidden) {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.container}>
          <FormControl className={classes.form} variant="outlined" error={errorDuplicateData || error}>
            {!disabled ? (
              <MaterialTable
                title={label}
                columns={tableColumns}
                data={data}
                isLoading={loading}
                totalCount={totalCount}
                page={currentPage > 0 ? currentPage - 1 : currentPage}
                editable={{
                  onRowAdd: (newData) => new Promise((resolve, reject) => {
                    setTimeout(() => {
                      setErrorUrl(false);
                      setErrorRequiredField(false);
                      setErrorDuplicateData(false);
                      resolve();

                      const isDuplicated = isDuplicatedData(data,
                        addedData(customLookUpField, newData),
                        customValidationField);
                      const isEmpty = isEmptyRequiredField(newData, requiredTableColumnField,
                        optionalTableColumnField);

                      if (!isDuplicated) {
                        if (isEmpty) {
                          setErrorRequiredField(true);
                          reject();
                        }
                        if (isErrorLink(newData)) {
                          setErrorUrl(true);
                          reject();
                        }
                        if (!isEmpty && !isErrorLink(newData)) {
                          setErrorUrl(false);
                          setErrorRequiredField(false);
                          setErrorDuplicateData(false);
                          const newArr = [...data, addedData(customLookUpField, newData)];
                          setData(newArr);
                          onAddPressed(newArr, newData);
                        }
                      } else {
                        setErrorDuplicateData(true);
                        reject();
                      }
                    }, 600);
                  }),
                  onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                    setTimeout(() => {
                      setErrorUrl(false);
                      setErrorRequiredField(false);
                      setErrorDuplicateData(false);
                      resolve();

                      const isDuplicated = isDuplicatedData(data,
                        addedData(customLookUpField, newData),
                        customValidationField,
                        oldData);
                      const isEmpty = isEmptyRequiredField(newData, requiredTableColumnField,
                        optionalTableColumnField);

                      if (oldData && !isDuplicated) {
                        if (isEmpty) {
                          setErrorRequiredField(true);
                          reject();
                        }
                        if (isErrorLink(newData)) {
                          setErrorUrl(true);
                          reject();
                        }
                        if (!isEmpty && !isErrorLink(newData)) {
                          setErrorUrl(false);
                          setErrorRequiredField(false);
                          setErrorDuplicateData(false);
                          setData(() => {
                            const newArr = [...data];
                            newArr[newArr.indexOf(oldData)] = addedData(customLookUpField, newData);
                            setData(newArr);
                            onAddPressed(newArr);
                          });
                        }
                      }
                      if (isDuplicated) {
                        setErrorDuplicateData(true);
                        reject();
                      }
                    }, 600);
                  }),
                  onRowDelete: (oldData) => new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      const newArr = [...data];
                      newArr.splice(newArr.indexOf(oldData), 1);
                      setData([...newArr]);
                      onDeletePressed([...newArr], oldData);
                    }, 600);
                  }),
                }}
                options={{
                  search,
                  doubleHorizontalScroll: false,
                  draggable: false,
                  actionsColumnIndex: tableColumns.length,
                  paging,
                  showTitle: !disableToolbar,
                  toolbar: !disableToolbar,
                  tableLayout: tableLayout || undefined,
                  headerStyle: {
                    backgroundColor: COLOR_BACKGROUND,
                    padding: selection ? '0px' : '4px 16px',
                    fontSize: '12px',
                    borderTop: `1px solid ${COLOR_SECONDARY}`,
                    borderBottom: `1px solid ${COLOR_SECONDARY}`,
                    whiteSpace: 'nowrap',
                    zIndex: 0,
                  },
                  pageSize: selectedPageSize,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  showEmptyDataSourceMessage: !!(data.length === 0 && !loading),
                  emptyRowsWhenPaging: false,
                  debounceInterval: 2000,
                  rowStyle: rowStyle || ((rowData, index) => {
                    const newIndex = index + 1;
                    const isEven = isEvenNumber(newIndex);
                    const rowBgColor = isEven ? COLOR_TERTIARY : COLOR_BACKGROUND;
                    return ({
                      backgroundColor: rowData.status === STATUS_DISABLED
                        ? COLOR_DISABLED_ROW : rowBgColor,
                      padding: '0px 16px',
                    });
                  }),
                  cellStyle: {
                    padding: selection ? '0px' : '0px 16px',
                    fontSize: '12px',
                    borderTop: `1px solid ${COLOR_SECONDARY}`,
                    borderBottom: `1px solid ${COLOR_SECONDARY}`,
                  },
                }}
                components={{
                  Container: (prop) => <Paper elevation={0} {...prop} />,
                  Action: (prop) => {
                    const {
                      action, data: actionData, size, disabled: disableButton,
                    } = prop;
                    const { tooltip, onClick } = action;
                    if (tooltip === 'Add') {
                      if (addMenuList.length > 0) {
                        return (
                          <div>
                            <AccentButton
                              startIcon={<ArrowDropDown />}
                              caption={addButtonCaption || LocalizedString.common.buttonCaptionAdd}
                              onClick={onOpenMoreMenu}
                              size="small"
                            />

                            <Menu
                              keepMounted
                              open={state.mouseY !== null}
                              onClose={onCloseMoreMenu}
                              anchorReference="anchorPosition"
                              anchorPosition={
                              state.mouseY !== null && state.mouseX !== null
                                ? { top: state.mouseY, left: state.mouseX }
                                : undefined
                            }
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: 200,
                                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                                },
                              }}
                            >
                              {addMenuList.map((item) => (
                                <MenuItem
                                  key={item.caption}
                                  onClick={(event) => {
                                    item.onPress();
                                    onCloseMoreMenu();
                                    onClick(event, actionData);
                                    event.stopPropagation();
                                  }}
                                >
                                  {item.caption}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div>
                        );
                      }
                      return (
                        <AccentButton
                          startIcon={<AddCircleOutlineRounded />}
                          caption={addButtonCaption || LocalizedString.common.buttonCaptionAdd}
                          onClick={(event) => {
                            setErrorUrl(false);
                            setErrorRequiredField(false);
                            setErrorDuplicateData(false);
                            onClick(event, actionData);
                          }}
                        />
                      );
                    }
                    if (tooltip === 'Save') {
                      return (
                        <AccentButton
                          variant="outlined"
                          caption={LocalizedString.common.buttonCaptionApply}
                          onClick={(event) => onClick(event, actionData)}
                          size="small"
                        />
                      );
                    }
                    if (tooltip === 'Cancel') {
                      return (
                        <AccentButton
                          variant="text"
                          caption={LocalizedString.common.buttonCaptionCancel}
                          className={classes.redButton}
                          onClick={(event) => onClick(event, actionData)}
                          size="small"
                        />
                      );
                    }
                    let newAction = action;
                    const newActionData = newAction(actionData);

                    if (disableEdit && newActionData.tooltip === 'Delete') {
                      if (customDeleteButtonCaption) {
                        return (
                          <AccentButton
                            variant="text"
                            caption={customDeleteButtonCaption}
                            onClick={(event) => {
                              if (typeof newAction === 'function') { newAction = newActionData; }
                              newAction.onClick(event, actionData);
                            }}
                            disabled={disableDelete || disableButton}
                            size="small"
                          />
                        );
                      }
                      return (
                        <IconButton
                          onClick={(event) => {
                            if (typeof newAction === 'function') { newAction = newActionData; }
                            newAction.onClick(event, actionData);
                          }}
                          disabled={disableDelete || disableButton}
                          size={size}
                        >
                          <img alt="delete" src={deleteIcon} width={21} height={21} disabled={disableDelete || disableButton} />
                        </IconButton>
                      );
                    }
                    if (disableEdit && newActionData.tooltip === 'Edit') { return null; }
                    if (disableDelete && newActionData.tooltip === 'Delete') { return null; }
                    return (
                      <IconButton
                        onClick={(event) => {
                          if (onEditPressed && newActionData.tooltip === 'Edit') {
                            onEditPressed(event, actionData);
                          } else {
                            if (typeof newAction === 'function') { newAction = newActionData; }
                            newAction.onClick(event, actionData);
                          }
                        }}
                        disabled={disableDelete || disableButton}
                        size={size}
                      >
                        {newActionData.tooltip === 'Edit'
                          ? <CircleEditOutline className={classes.greenButton} />
                          : (
                            <img alt="delete" src={deleteIcon} width={21} height={21} disabled={disableDelete || disableButton} />
                          )}
                      </IconButton>
                    );
                  },
                }}
                localization={customDeleteConfirmationMessage ? {
                  body: { editRow: { deleteText: customDeleteConfirmationMessage } },
                } : undefined}
                onChangePage={(page) => onChangePage(page)}
                onChangeRowsPerPage={(pageSize) => onChangePageSize(pageSize)}
                onOrderChange={(orderedColumnId, orderDirection) => onSortPress(orderedColumnId,
                  orderDirection, orderBy, tableColumns, onSortPressed)}
                icons={{
                  Search: forwardRef((iconProps, ref) => (
                    <Search
                      {...iconProps}
                      ref={ref}
                      style={newStyles.tableIcon}
                    />
                  )),
                  ResetSearch: forwardRef((iconProps, ref) => (
                    <Clear
                      {...iconProps}
                      ref={ref}
                      style={newStyles.tableIcon}
                    />
                  )),
                  SortArrow: forwardRef((iconProps, ref) => (
                    <ArrowUpward
                      {...iconProps}
                      ref={ref}
                      style={newStyles.tableSortIcon}
                    />
                  )),
                  FirstPage: forwardRef((iconProps, ref) => (
                    <SkipPreviousOutlined
                      {...iconProps}
                      ref={ref}
                      style={newStyles.tableBaseIcon}
                    />
                  )),
                  LastPage: forwardRef((iconProps, ref) => (
                    <SkipNextOutlined
                      {...iconProps}
                      ref={ref}
                      style={newStyles.tableBaseIcon}
                    />
                  )),
                }}
                style={{ border: `1px solid ${COLOR_SECONDARY}` }}
                {...props}
              />
            ) : (
              <MaterialTable
                key={selectedPageSize ?? label}
                title={label}
                columns={tableColumns}
                data={data}
                isLoading={loading}
                searchText={decodeURIComponent(initSearchText)}
                onSearchChange={(text) => {
                  setInitSearchText(encodeURIComponent(text));
                  onSearchBarTextChanged(encodeURIComponent(text));
                }}
                totalCount={totalCount}
                page={currentPage > 0 ? currentPage - 1 : currentPage}
                actions={actions}
                options={{
                  search,
                  doubleHorizontalScroll: filterColumns.length > 0,
                  draggable: false,
                  actionsColumnIndex: disableActions ? tableColumns.length : actionsColumnIndex,
                  paging,
                  showTitle: !disableToolbar,
                  toolbar: filterColumns.length ? true : !disableToolbar,
                  tableLayout: tableLayout || undefined,
                  headerStyle: {
                    backgroundColor: COLOR_BACKGROUND,
                    padding: selection ? '0px' : '4px 16px',
                    fontSize: '12px',
                    borderTop: `1px solid ${COLOR_SECONDARY}`,
                    borderBottom: `1px solid ${COLOR_SECONDARY}`,
                    whiteSpace: 'nowrap',
                    zIndex: 0,
                  },
                  searchFieldStyle: {
                    border: `1px solid ${COLOR_SECONDARY}`,
                    borderRadius: 2,
                    fontSize: '12px',
                  },
                  pageSize: selectedPageSize,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  showEmptyDataSourceMessage: !!(data.length === 0 && !loading),
                  emptyRowsWhenPaging: false,
                  debounceInterval: 2000,
                  searchText: decodeURIComponent(initSearchText),
                  padding: 0,
                  rowStyle: rowStyle || ((rowData, index) => {
                    const newIndex = index + 1;
                    const isEven = isEvenNumber(newIndex);
                    const rowBgColor = isEven ? COLOR_TERTIARY : COLOR_BACKGROUND;
                    return ({
                      backgroundColor: rowData.status === STATUS_DISABLED
                        ? COLOR_DISABLED_ROW : rowBgColor,
                      padding: rowPadding,
                    });
                  }),
                  selection,
                  selectionProps: (rowData) => ({
                    checked: rowData?.tableData?.checked || false,
                    style: { padding: '0px 0px 0px 8px' },
                  }),
                  showTextRowsSelected: false,
                  cellStyle: {
                    padding: rowPadding,
                    fontSize: '12px',
                    borderTop: `1px solid ${COLOR_SECONDARY}`,
                    borderBottom: `1px solid ${COLOR_SECONDARY}`,
                  },
                }}
                components={{
                  Container: (prop) => <Paper elevation={0} {...prop} />,
                  Toolbar: (prop) => filterColumns.length > 0 && (
                    <div>
                      <MTableToolbar {...prop} />
                      <div className={classes.filterActionContainer}>
                        <div className={classes.filterButtonContainer}>
                          <Badge color="error" badgeContent=" " invisible={!filterString} classes={{ anchorOriginTopRightRectangle: classes.badge }}>
                            <AccentButton
                              variant="outlined"
                              caption={LocalizedString.common.buttonCaptionAdvancedFilter}
                              endIcon={<img alt="filter" src={filterIcon} width={12} height={12} />}
                              disabled={loading}
                              onClick={() => {
                                onAdvancedFilterPressed();
                                setAdvancedFilterVisibility(true);
                              }}
                              size="small"
                              style={classes.buttonOutline}
                            />
                          </Badge>
                        </div>
                      </div>
                    </div>
                  ),
                }}
                onChangePage={(page) => onChangePage(page)}
                onChangeRowsPerPage={(pageSize) => onChangePageSize(pageSize)}
                onOrderChange={(orderedColumnId, orderDirection) => onSortPress(orderedColumnId,
                  orderDirection, orderBy, tableColumns, onSortPressed)}
                icons={{
                  Search: forwardRef((iconProps, ref) => (
                    <Search
                      {...iconProps}
                      ref={ref}
                      style={newStyles.tableIcon}
                    />
                  )),
                  ResetSearch: forwardRef((iconProps, ref) => (
                    <Clear
                      {...iconProps}
                      ref={ref}
                      style={newStyles.tableIcon}
                    />
                  )),
                  SortArrow: forwardRef((iconProps, ref) => (
                    <ArrowUpward
                      {...iconProps}
                      ref={ref}
                      style={newStyles.tableSortIcon}
                    />
                  )),
                  FirstPage: forwardRef((iconProps, ref) => (
                    <SkipPreviousOutlined
                      {...iconProps}
                      ref={ref}
                      style={newStyles.tableBaseIcon}
                    />
                  )),
                  LastPage: forwardRef((iconProps, ref) => (
                    <SkipNextOutlined
                      {...iconProps}
                      ref={ref}
                      style={newStyles.tableBaseIcon}
                    />
                  )),
                }}
                style={{ border: `1px solid ${COLOR_SECONDARY}` }}
                onSelectionChange={(rows) => {
                  setSelectedRows(rows);
                  onSelectionChange(rows);
                }}
                {...props}
              />
            )}

            {filterColumns.length > 0 && (
            <AdvancedFilterDialog
              advancedFilterVisibility={advancedFilterVisibility}
              downloading={loading}
              filterColumns={filterColumns}
              filterSections={filterSections}
              onClosePressed={() => setAdvancedFilterVisibility(false)}
              onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
              onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
              onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
              useTwoColumnFilterDialog={useTwoColumnFilterDialog}
            />
            )}
            <FormHelperText className={classes.helperText}>{errorText}</FormHelperText>
          </FormControl>
        </div>
      </ThemeProvider>
    );
  }
  return null;
};

export default EditableTableField;

const MoreMenuShape = PropTypes.shape({
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
});

EditableTableField.propTypes = {
  addMenuList: PropTypes.arrayOf(PropTypes.object),
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  filterColumns: PropTypes.arrayOf(TableColumnShape),
  filterSections: PropTypes.arrayOf(TableColumnShape),
  moreMenuList: PropTypes.arrayOf(MoreMenuShape),
  optionalTableColumnField: PropTypes.arrayOf(PropTypes.string),
  requiredTableColumnField: PropTypes.arrayOf(PropTypes.string),
  tableColumns: PropTypes.arrayOf(TableColumnShape).isRequired,
  disableActions: PropTypes.bool,
  disabled: PropTypes.bool,
  disableDelete: PropTypes.bool,
  disableEdit: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  paging: PropTypes.bool,
  search: PropTypes.bool,
  selection: PropTypes.bool,
  useTwoColumnFilterDialog: PropTypes.bool,
  onAddPressed: PropTypes.func,
  onAdvancedFilterPressed: PropTypes.func,
  onApplyAdvancedFilterPressed: PropTypes.func,
  onCancelAdvancedFilterPressed: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangePageSize: PropTypes.func,
  onDeletePressed: PropTypes.func,
  onEditPressed: PropTypes.func,
  onMorePressed: PropTypes.func,
  onResetAdvancedFilterPressed: PropTypes.func,
  onSearchBarTextChanged: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onSortPressed: PropTypes.func,
  actionsColumnIndex: PropTypes.number,
  currentPage: PropTypes.number,
  selectedPageSize: PropTypes.number,
  totalCount: PropTypes.number,
  rowStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  addButtonCaption: PropTypes.string,
  customDeleteButtonCaption: PropTypes.string,
  customDeleteConfirmationMessage: PropTypes.string,
  customLookUpField: PropTypes.string,
  customValidationField: PropTypes.string,
  filterString: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  orderBy: PropTypes.string,
  searchBarText: PropTypes.string,
  tableLayout: PropTypes.string,
};

EditableTableField.defaultProps = {
  addMenuList: [],
  optionalTableColumnField: [],
  requiredTableColumnField: [],
  defaultValue: [],
  filterColumns: [],
  filterSections: [],
  moreMenuList: [],
  disableActions: true,
  disabled: false,
  disableDelete: false,
  disableEdit: false,
  disableToolbar: false,
  error: false,
  hidden: false,
  loading: false,
  paging: false,
  search: false,
  selection: false,
  useTwoColumnFilterDialog: true,
  rowStyle: null,
  onAddPressed: () => {},
  onAdvancedFilterPressed: () => {},
  onApplyAdvancedFilterPressed: () => {},
  onCancelAdvancedFilterPressed: () => {},
  onChangePage: () => {},
  onChangePageSize: () => {},
  onDeletePressed: () => {},
  onEditPressed: undefined,
  onMorePressed: () => {},
  onResetAdvancedFilterPressed: () => {},
  onSearchBarTextChanged: () => {},
  onSelectionChange: () => {},
  onSortPressed: () => {},
  actionsColumnIndex: 1,
  currentPage: 0,
  selectedPageSize: PAGE_SIZE_OPTIONS[0],
  totalCount: 0,
  addButtonCaption: null,
  customDeleteButtonCaption: null,
  customDeleteConfirmationMessage: null,
  customLookUpField: null,
  customValidationField: null,
  filterString: '',
  label: null,
  helperText: null,
  orderBy: '',
  searchBarText: '',
  tableLayout: 'auto',
};
