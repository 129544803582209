/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage, SectionTitle } from '../../component';
import {
  PAGE_MODE_VIEW,
  FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_FORMAT,
  FILTER_TYPE_SWITCH,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFORM_APPROVAL_GROUP,
  RXSTATE_APPROVAL_GROUPS,
  RXSTATE_APPROVAL_GROUP_PAGE,
  RXFIELD_APPROVAL_GROUP_DESCRIPTION,
  RXFIELD_APPROVAL_GROUP_NAME,
  RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS,
  APPROVAL_GROUP_STATUS,
  STATUS_ENABLED, STATUS_DISABLED,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormAutocompleteTableField,
} from '../../../../redux-form-rendererer';
import { toMoment } from '../../helper';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateApprovalGroup } from '../../validation';

const renderDialogContent = (
  initialValues, downloadDeletingApprovalGroup, addingEditingApprovalGroup, pageMode,
  groupMembers, downloadingGroupMembers, addingCustomGroup, tableDataGroupMembers,
  selectedMemberGroup, tappedApprovalGroup, totalCount, onAddGroupMembersPressed,
  onDeleteGroupMembersPressed, onChangeMemberGroupsText, onMemberGroupOptionSelected,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_GROUP_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalGroupPage.labelGroupName}
            label={LocalizedString.approvalGroupPage.labelGroupName}
            disabled={
              downloadDeletingApprovalGroup
              || addingEditingApprovalGroup
              || pageMode === PAGE_MODE_VIEW
            }
            onBlur={(e) => e.preventDefault()}
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_APPROVAL_GROUP_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.approvalGroupPage.labelDescription}
            label={LocalizedString.approvalGroupPage.labelDescription}
            disabled={
              downloadDeletingApprovalGroup
              || addingEditingApprovalGroup
              || pageMode === PAGE_MODE_VIEW
            }
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
      </Grid>
    </Grid>

    <Grid>
      <SectionTitle title={LocalizedString.approvalGroupPage.labelGroupMembers} />
      <Grid item sm={12}>
        <Field
          name={RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS}
          label={LocalizedString.approvalGroupPage.labelGroupMembers}
          component={renderReduxFormAutocompleteTableField}
          currentTableData={tableDataGroupMembers}
          defaultValue={initialValues.members}
          options={groupMembers}
          adding={addingCustomGroup}
          disableAdd={pageMode === PAGE_MODE_VIEW}
          disabled={
            downloadDeletingApprovalGroup
            || addingEditingApprovalGroup
            || pageMode === PAGE_MODE_VIEW
          }
          onAddPressed={(defaultValue, newInitialValue, selectedOption,
            currentData) => onAddGroupMembersPressed(defaultValue,
            newInitialValue, selectedOption, currentData)}
          loadingOptions={downloadingGroupMembers}
          onChangeOptionText={(text) => onChangeMemberGroupsText(text)}
          onDeletePressed={(newValue, currentData) => onDeleteGroupMembersPressed(
            newValue, currentData,
          )}
          onOptionSelected={onMemberGroupOptionSelected}
          selectedOption={selectedMemberGroup}
          currentTappedData={tappedApprovalGroup}
          onBlur={(e) => e.preventDefault()}
          totalCount={totalCount}
          tableColumns={[
            {
              title: LocalizedString.approvalGroupPage.labelID,
              field: 'value',
              sorting: false,
            },
            {
              title: LocalizedString.approvalGroupPage.labelName,
              field: 'label',
              sorting: false,
            },
            {
              title: LocalizedString.approvalGroupPage.labelEmail,
              field: 'email',
              sorting: false,
            },
          ]}
          required
        />
      </Grid>
    </Grid>
  </Grid>
);

const ApprovalGroupPage = ({
  initialValues, groupMembers, tableDataGroupMembers, downloading, downloadDeletingApprovalGroup,
  addingEditingApprovalGroup, downloadingGroupMembers, addingCustomGroup, enablingDisabling,
  pageMode, currentApprovalGroupStatus, selectedMemberGroup, tappedApprovalGroup, handleSubmit,
  onAppear, onCancelPressed, onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  onSortPressed, onViewPressed, onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  onCreatePressed, onEditPressed, onDeletePressed, onAddGroupMembersPressed,
  onDeleteGroupMembersPressed, onChangeMemberGroupsText, onMemberGroupOptionSelected,
  onSubmitPressed, onSavePressed, onConfirmContextMenuPressed, onContextMenuPressed,
}) => (
  <div>
    <FunctionalPage
      data={RXSTATE_APPROVAL_GROUPS}
      uiPage={RXSTATE_APPROVAL_GROUP_PAGE}
      moreMenus={[
        {
          caption: GlobalLocalizedString.common.buttonCaptionEnable,
          disabled: currentApprovalGroupStatus === STATUS_ENABLED || enablingDisabling,
          onPress: (id) => onContextMenuPressed(id,
            GlobalLocalizedString.common.msgEnableConfirmation),
        },
        {
          caption: GlobalLocalizedString.common.buttonCaptionDisable,
          disabled: currentApprovalGroupStatus === STATUS_DISABLED || enablingDisabling,
          onPress: (id) => onContextMenuPressed(id,
            GlobalLocalizedString.common.msgDisableConfirmation),
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.approvalGroupPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        {
          title: LocalizedString.approvalGroupPage.labelName, field: 'name', sorting: !downloading,
        },
        { title: LocalizedString.approvalGroupPage.labelDescription, field: 'description', sorting: !downloading },
        {
          title: LocalizedString.approvalGroupPage.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.approvalGroupPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_FORMAT,
        },
        {
          title: LocalizedString.approvalGroupPage.labelStatus,
          field: 'status',
          type: FILTER_TYPE_SWITCH,
          data: APPROVAL_GROUP_STATUS,
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onCreatePressed={onCreatePressed}
      onDeletePressed={onDeletePressed}
      onEditPressed={onEditPressed}
      useFullWidth
      usefullWidthDialog
      title={LocalizedString.approvalGroupPage.title}
      useTwoColumnFilterDialog
      createNewButtonCaption={
        LocalizedString.approvalGroupPage.buttonCaptionCreateNewApprovalGroup
      }
      deleteButtonCaption={LocalizedString.approvalGroupPage.buttonCaptionDeleteApprovalGroup}
      editButtonCaption={LocalizedString.approvalGroupPage.buttonCaptionEditApprovalGroup}
      onSubmitPressed={onSubmitPressed}
      onSavePressed={onSavePressed}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      createPermissionName="APPROVAL_CREATE_APPROVAL_GROUP"
      editPermissionName="APPROVAL_EDIT_APPROVAL_GROUP"
      deletePermissionName="APPROVAL_DELETE_APPROVAL_GROUP"
      savePermissionName="APPROVAL_GET_APPROVAL_GROUP"
    >
      {renderDialogContent(
        initialValues,
        downloadDeletingApprovalGroup,
        addingEditingApprovalGroup,
        pageMode,
        groupMembers,
        downloadingGroupMembers,
        addingCustomGroup,
        tableDataGroupMembers,
        selectedMemberGroup,
        tappedApprovalGroup,
        tableDataGroupMembers?.length || 0,
        onAddGroupMembersPressed,
        onDeleteGroupMembersPressed,
        onChangeMemberGroupsText,
        onMemberGroupOptionSelected,
      )}
    </FunctionalPage>
  </div>
);

export default reduxForm({
  form: RXFORM_APPROVAL_GROUP,
  validate: rxformValidateApprovalGroup,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ApprovalGroupPage);

ApprovalGroupPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  groupMembers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  tableDataGroupMembers: PropTypes.arrayOf(SimpleDataShape).isRequired,

  downloading: PropTypes.bool.isRequired,
  downloadDeletingApprovalGroup: PropTypes.bool.isRequired,
  downloadingGroupMembers: PropTypes.bool.isRequired,
  addingCustomGroup: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,

  pageMode: PropTypes.string.isRequired,
  selectedMemberGroup: PropTypes.string.isRequired,
  tappedApprovalGroup: PropTypes.string.isRequired,
  currentApprovalGroupStatus: PropTypes.string.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onAddGroupMembersPressed: PropTypes.func.isRequired,
  onDeleteGroupMembersPressed: PropTypes.func.isRequired,
  onChangeMemberGroupsText: PropTypes.func.isRequired,
  onMemberGroupOptionSelected: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
};
