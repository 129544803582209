import {
  setAlertErrorMessage, setFunctionalPageMode, setFlowDesignerTappedId,
  downloadingDeletingFlowDesigner, addFlowDesigner, clearFlowDesigners,
  setDroppableFieldField, setFlowDesignerApprovers,
} from '../simple-action';
import { downloadFlowDesigner, deleteFlowDesigner, sortAsc } from '../../../helper';
import {
  PAGE_MODE_TABLE, TYPE_REGULAR,
} from '../../../constant';
import downloadFlowDesignersAsync from './downloadFlowDesignersAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingFlowDesigner(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiFlowDesigner;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteFlowDesigner(tappedId, token);
      dispatch(setFlowDesignerTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearFlowDesigners());
      dispatch(downloadFlowDesignersAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      const flowDesigner = await downloadFlowDesigner(tappedId, token);
      const sortedApprovers = {
        ...flowDesigner,
        workflow: flowDesigner.workflow.sort((a, b) => sortAsc(a.order, b.order)),
      };
      dispatch(addFlowDesigner(sortedApprovers));
      dispatch(setDroppableFieldField(sortedApprovers.workflow));
      sortedApprovers.workflow.filter((data) => data.type === TYPE_REGULAR).forEach((data) => {
        const processedWorkflow = data.approvers.map((x, i) => ({
          data: {
            ...x,
            no: i + 1,
          },
        }));
        const retval = {
          data: processedWorkflow,
          stepId: data.id,
        };
        dispatch(setFlowDesignerApprovers(retval));
      });
    }
  } finally {
    dispatch(downloadingDeletingFlowDesigner(false));
  }
};
