import {
  SET_DROPPABLE_FIELD_FIELD, CLEAR_DROPPABLE_FIELD_FIELD,
} from '../action';

const initialState = {
  fields: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DROPPABLE_FIELD_FIELD:
      return { ...state, fields: action.data };
    case CLEAR_DROPPABLE_FIELD_FIELD:
      return initialState;
    default: return state;
  }
};
