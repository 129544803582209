import PropTypes from 'prop-types';

export * from '../../type';

export const UserShape = PropTypes.shape({
  id: PropTypes.string,
  fullName: PropTypes.string,
});

export const GroupShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const ApproverShape = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  group: GroupShape,
  user: UserShape,
  managerLevel: PropTypes.number,
  groupMode: PropTypes.string,
});

export const WorkflowShape = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  stepName: PropTypes.string,
  order: PropTypes.number,
  maxLevel: PropTypes.string,
  approverMode: PropTypes.string,
  approvers: PropTypes.arrayOf(ApproverShape),
});

export const FlowDesignerShape = PropTypes.shape({
  id: PropTypes.string,
  processName: PropTypes.string,
  description: PropTypes.string,
  workflow: PropTypes.arrayOf(WorkflowShape),
});
