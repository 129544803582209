import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Add } from '@material-ui/icons';
import { Field, reduxForm } from 'redux-form';
import { AccentButton, EditableTableField, VerticalSpacer } from '../../../component';
import {
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME, REVERSED_ISO_DATE_FORMAT,
  ROUTE_NAME_ASSET_DETAIL, RXFIELD_ASSET_ASSET_DESCRIPTION, RXFIELD_ASSET_ASSET_NUMBER,
  RXFIELD_ASSET_ASSET_TYPE, RXFIELD_ASSET_CERTIFICATE_EXPIRATION_DATE,
  RXFIELD_ASSET_CHILD_CHILDREN, RXFIELD_ASSET_LOCATION, RXFIELD_ASSET_PART_CODE,
  RXFIELD_ASSET_SERIAL_NUMBER, RXFIELD_ASSET_SOURCE_ID, RXFORM_ASSET_CHILD_TAB,
} from '../../../constant';
import GlobalLocalizedString from '../../../../../localization';
import LocalizedString from '../../../localization';
import StyledDialog from '../../../../../component/styled-dialog';
import { toMoment } from '../../../helper';
import { renderReduxFormEditableTableField } from '../../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../../type';

const AssetChildTab = ({
  assetChildren, assetTypes, locations, parentlessAssets,
  adding, deleting, dialogVisibility, downloading, downloadingParentlessAssets, hasCreatePermission,
  hasDeletePermission, loadingAssetTypes, loadingLocations,
  onAddPressed, onAppear, onApplyParentlessAssetAdvancedFilterPressed,
  onCancelPressed, onChangeAssetTypeText, onChangeLocationText, onChangePage, onChangePageSize,
  onChangeParentlessAssetPage, onChangeParentlessAssetPageSize, onDeletePressed,
  onParentlessAssetAdvancedFilterPressed, onParentlessAssetSearchBarTextChanged,
  onParentlessAssetSortPressed, onResetParentlessAssetAdvancedFilterPressed, onSortPressed,
  onSubmitPressed,
  currentPage, parentlessAssetCurrentPage, parentlessAssetTotalCount,
  parentlessAssetSelectedPageSize, selectedPageSize, totalCount,
  orderBy, parentlessAssetFilterString, parentlessAssetOrderBy, parentlessAssetSearchBarText,
}) => {
  const [selectedAssets, setSelectedAssets] = useState([]);

  const isDetailRoute = useRouteMatch(ROUTE_NAME_ASSET_DETAIL);
  const detailIdParam = isDetailRoute?.params?.id;

  useEffect(() => onAppear(detailIdParam), [onAppear, detailIdParam]);

  return (
    <div>
      {hasCreatePermission && (
      <AccentButton
        onClick={onAddPressed}
        variant="contained"
        caption={LocalizedString.assetPage.buttonCaptionAddAsset}
        disabled={downloading}
        endIcon={<Add />}
      />
      )}

      {hasCreatePermission && (<VerticalSpacer />)}

      <EditableTableField
        data={assetChildren}
        loading={deleting || downloading}
        tableColumns={[
          {
            title: LocalizedString.assetPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.assetPage.labelId, field: 'sourceId', sorting: !downloading },
          { title: LocalizedString.assetPage.labelAssetDescription, field: 'assetDescription', sorting: !downloading },
          { title: LocalizedString.assetPage.labelPartCode, field: 'partCode', sorting: !downloading },
          { title: LocalizedString.assetPage.labelSerialNumber, field: 'serialNumber', sorting: !downloading },
          { title: LocalizedString.assetPage.labelAssetNumber, field: 'assetNumber', sorting: !downloading },
        ]}
        disableDelete={!hasDeletePermission}
        disableEdit
        disableToolbar
        paging
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onDeletePressed={onDeletePressed}
        onSortPressed={onSortPressed}
        currentPage={currentPage}
        totalCount={totalCount}
        selectedPageSize={selectedPageSize}
        orderBy={orderBy}
      />

      <StyledDialog
        disableSubmitButton={!selectedAssets.length || downloadingParentlessAssets}
        submitting={adding}
        visibility={dialogVisibility}
        onCancelPressed={onCancelPressed}
        onSubmitPressed={() => onSubmitPressed(selectedAssets)}
        title={LocalizedString.assetPage.titleAddChildAssets}
        submitButtonCaption={GlobalLocalizedString.common.buttonCaptionAdd}
      >
        <Field
          name={RXFIELD_ASSET_CHILD_CHILDREN}
          component={renderReduxFormEditableTableField}
          data={parentlessAssets}
          loading={downloadingParentlessAssets}
          selection
          tableColumns={[
            { title: LocalizedString.assetPage.labelId, field: 'sourceId', sorting: !downloadingParentlessAssets },
            { title: LocalizedString.assetPage.labelAssetDescription, field: 'assetDescription', sorting: !downloadingParentlessAssets },
            { title: LocalizedString.assetPage.labelPartCode, field: 'partCode', sorting: !downloadingParentlessAssets },
            { title: LocalizedString.assetPage.labelSerialNumber, field: 'serialNumber', sorting: !downloadingParentlessAssets },
            { title: LocalizedString.assetPage.labelAssetNumber, field: 'assetNumber', sorting: !downloadingParentlessAssets },
            { title: LocalizedString.assetPage.labelAssetClass, field: 'className', sorting: !downloadingParentlessAssets },
            { title: LocalizedString.assetPage.labelLocation, field: 'location.name', sorting: !downloadingParentlessAssets },
            {
              title: LocalizedString.assetPage.labelExpired,
              field: 'certificateExpirationDate',
              sorting: !downloading,
              render: ({ certificateExpirationDate }) => (certificateExpirationDate
                ? toMoment(certificateExpirationDate).format(REVERSED_ISO_DATE_FORMAT) : null),
            },
          ]}
          filterColumns={[
            {
              title: LocalizedString.assetPage.labelId,
              field: RXFIELD_ASSET_SOURCE_ID,
              type: FILTER_TYPE_TEXT,
            },
            {
              title: LocalizedString.assetPage.labelAssetDescription,
              field: RXFIELD_ASSET_ASSET_DESCRIPTION,
              type: FILTER_TYPE_TEXT,
            },
            {
              title: LocalizedString.assetPage.labelPartCode,
              field: RXFIELD_ASSET_PART_CODE,
              type: FILTER_TYPE_TEXT,
            },
            {
              title: LocalizedString.assetPage.labelSerialNumber,
              field: RXFIELD_ASSET_SERIAL_NUMBER,
              type: FILTER_TYPE_TEXT,
            },
            {
              title: LocalizedString.assetPage.labelAssetNumber,
              field: RXFIELD_ASSET_ASSET_NUMBER,
              type: FILTER_TYPE_TEXT,
            },
            {
              title: LocalizedString.assetPage.labelAssetClass,
              field: RXFIELD_ASSET_ASSET_TYPE,
              type: FILTER_TYPE_DROPDOWN,
              data: assetTypes,
              loading: loadingAssetTypes,
              onChangeFilterText: onChangeAssetTypeText,
              useDropdownValue: true,
            },
            {
              title: LocalizedString.assetPage.labelLocation,
              field: RXFIELD_ASSET_LOCATION,
              type: FILTER_TYPE_DROPDOWN,
              data: locations,
              loading: loadingLocations,
              onChangeFilterText: onChangeLocationText,
              useDropdownValue: true,
            },
            {
              title: LocalizedString.assetPage.labelExpired,
              field: RXFIELD_ASSET_CERTIFICATE_EXPIRATION_DATE,
              type: FILTER_TYPE_DATE_RANGE,
              pickerMode: PICKER_MODE_DATE_TIME,
              format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
            },
          ]}
          disabled
          paging
          search
          onAdvancedFilterPressed={onParentlessAssetAdvancedFilterPressed}
          onApplyAdvancedFilterPressed={onApplyParentlessAssetAdvancedFilterPressed}
          onChangePage={onChangeParentlessAssetPage}
          onChangePageSize={onChangeParentlessAssetPageSize}
          onResetAdvancedFilterPressed={onResetParentlessAssetAdvancedFilterPressed}
          onSearchBarTextChanged={onParentlessAssetSearchBarTextChanged}
          onSelectionChange={(rows) => {
            const data = rows.map((x) => x.id);
            setSelectedAssets(data);
          }}
          onSortPressed={onParentlessAssetSortPressed}
          filterString={parentlessAssetFilterString}
          searchBarText={parentlessAssetSearchBarText}
          currentPage={parentlessAssetCurrentPage}
          totalCount={parentlessAssetTotalCount}
          selectedPageSize={parentlessAssetSelectedPageSize}
          orderBy={parentlessAssetOrderBy}
        />
      </StyledDialog>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_ASSET_CHILD_TAB,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AssetChildTab);

AssetChildTab.propTypes = {
  assetChildren: PropTypes.arrayOf(PropTypes.object).isRequired,
  assetTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  locations: PropTypes.arrayOf(SimpleDataShape).isRequired,
  parentlessAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
  adding: PropTypes.bool.isRequired,
  deleting: PropTypes.bool.isRequired,
  dialogVisibility: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingParentlessAssets: PropTypes.bool.isRequired,
  hasCreatePermission: PropTypes.bool.isRequired,
  hasDeletePermission: PropTypes.bool.isRequired,
  loadingAssetTypes: PropTypes.bool.isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  onAddPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyParentlessAssetAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeAssetTypeText: PropTypes.func.isRequired,
  onChangeLocationText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeParentlessAssetPage: PropTypes.func.isRequired,
  onChangeParentlessAssetPageSize: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onParentlessAssetAdvancedFilterPressed: PropTypes.func.isRequired,
  onParentlessAssetSearchBarTextChanged: PropTypes.func.isRequired,
  onParentlessAssetSortPressed: PropTypes.func.isRequired,
  onResetParentlessAssetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  parentlessAssetCurrentPage: PropTypes.number.isRequired,
  parentlessAssetTotalCount: PropTypes.number.isRequired,
  parentlessAssetSelectedPageSize: PropTypes.number.isRequired,
  selectedPageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  orderBy: PropTypes.string.isRequired,
  parentlessAssetFilterString: PropTypes.string.isRequired,
  parentlessAssetOrderBy: PropTypes.string.isRequired,
  parentlessAssetSearchBarText: PropTypes.string.isRequired,
};
