import { connect } from 'react-redux';
import {
  change, blur, reset, untouch,
} from 'redux-form';
import {
  PAGE_MODE_TABLE, PAGE_MODE_EDIT, RXFORM_FLOW_DESIGNER, MENUID_FLOW_DESIGNER,
  INITIAL_ORDER_BY_FLOW_DESIGNERS, PAGE_MODE_CREATE, RXFIELD_FLOW_DESIGNER_TYPE,
  RXFIELD_FLOW_DESIGNER_APPROVER_MODE, RXFIELD_FLOW_DESIGNER_APPROVERS,
  RXFIELD_FLOW_DESIGNER_MAX_LEVEL,
  TYPE_DIRECT_MANAGER,
} from '../../constant';
import {
  getName, toNumber, transformInitialValues,
} from '../../helper';
import FlowDesignerPage from './flow-designer.presentation';
import {
  downloadDeleteFlowDesignerAsync, downloadFlowDesignersAsync, setAlertErrorMessage,
  setActiveSideMenuItem, clearFlowDesigners, setFlowDesignerSearchText,
  setFlowDesignerAdvancedFilterDialogSelectedFilterString,
  setFlowDesignerSelectedOrderBy, setFlowDesignerSelectedPageSize, setFlowDesignerTappedId,
  setFunctionalPageMode, setFlowDesignerSelectedApproverMode, setAlertConfirmationMessage,
  setApproverTableData, setApproverTableSelectedStepId, clearFlowDesignerApprovers,
  setFlowDesignerApproversDialogVisibility, addEditFlowDesignerAsync,
  setFlowDesignerSelectedProcessFlowIndex, setFlowDesignerApprovers, setFlowDesignerSelectedType,
} from '../../redux/action';
import GlobalLocalizedString from '../../../../localization';

const getInitialValues = (state) => {
  const { flowDesigners, uiFlowDesigner, uiFunctionalPage } = state;
  const { downloadDeletingFlowDesigner, tappedId } = uiFlowDesigner;
  const { pageMode } = uiFunctionalPage;

  const found = (
    pageMode !== PAGE_MODE_TABLE && pageMode !== PAGE_MODE_CREATE
  ) && !downloadDeletingFlowDesigner
    ? flowDesigners.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0 ? transformInitialValues(found, {
    processName: found?.processName,
    description: found?.description,
    workflow: found?.workflow.length > 0 ? found?.workflow : [],
  }) : {
    processName: '',
    description: '',
    workflow: [],
  };

  return initVal;
};

const getFieldSwapValue = (state) => {
  const { uiDroppableField } = state;
  const { fields } = uiDroppableField;

  return fields.length > 0 ? fields : [];
};

const checkEmptyFormValue = (state) => {
  const { form } = state;
  const { flowDesignerPage } = form;

  if (flowDesignerPage?.values?.processName === '' || flowDesignerPage?.values?.workflow.length === 0) {
    return true;
  }
  return false;
};

const mapStateToProps = (state) => ({
  downloading: state.uiFlowDesigner.downloading,
  addingEditingFlowDesigner: state.uiFlowDesigner.addingEditingFlowDesigner,
  downloadDeletingFlowDesigner: state.uiFlowDesigner.downloadDeletingFlowDesigner,
  initialValues: getInitialValues(state),
  fieldSwapValue: getFieldSwapValue(state),
  pageMode: state.uiFunctionalPage.pageMode,
  selectedType: state.uiFlowDesigner.selectedType,
  approversTable: Object.values(state.uiFlowDesigner.approvers),
  isOpen: state.uiFlowDesigner.approverDialogVisibility,
  addingApprovers: state.uiFlowDesigner.addingApprovers,
  tappedApprovers: state.flowDesigners.data[state.uiFlowDesigner.tappedId],
  selectedApproverIndex: state.uiFlowDesigner.selectedApproverIndex,
  steps: state?.form?.flowDesignerPage?.values?.workflow || [],
  emptyFormValue: checkEmptyFormValue(state),
  tappedId: state.uiFlowDesigner.tappedId,
});

const clearFields = (dispatch, value, workflow, approversTable, field) => {
  if (value === TYPE_DIRECT_MANAGER) {
    const slicedIndex = toNumber(workflow.slice(9, 10));
    const deletedData = field[slicedIndex];
    const deletedDataArrayIndex = approversTable.findIndex((x) => x.stepId === deletedData.id);
    const numberToDelete = deletedDataArrayIndex === -1 ? 0 : 1;
    approversTable.splice(deletedDataArrayIndex, numberToDelete);

    if (approversTable.length === 0) {
      dispatch(clearFlowDesignerApprovers());
    }
    if (approversTable.length > 0) {
      dispatch(clearFlowDesignerApprovers());
      approversTable.forEach((x) => dispatch(setFlowDesignerApprovers(x)));
    }
    dispatch(setFlowDesignerSelectedApproverMode(''));
    dispatch(change(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_APPROVER_MODE}`, ''));
    dispatch(untouch(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_APPROVER_MODE}`));
    dispatch(untouch(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_APPROVERS}`));
  } else {
    dispatch(change(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_MAX_LEVEL}`, ''));
    dispatch(untouch(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_MAX_LEVEL}`));
  }
};

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setFlowDesignerAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_FLOW_DESIGNER));
    dispatch(setFlowDesignerSearchText(''));
    dispatch(clearFlowDesigners());
    dispatch(setFlowDesignerSelectedPageSize(20));
    dispatch(setFlowDesignerSelectedOrderBy(INITIAL_ORDER_BY_FLOW_DESIGNERS));
    dispatch(downloadFlowDesignersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setFlowDesignerAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearFlowDesigners());
    dispatch(downloadFlowDesignersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onBackPressed: () => {
    dispatch(reset(RXFORM_FLOW_DESIGNER));
    dispatch(clearFlowDesignerApprovers());
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_FLOW_DESIGNER));
    dispatch(clearFlowDesignerApprovers());
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadFlowDesignersAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setFlowDesignerSelectedPageSize(pageSize));
    dispatch(downloadFlowDesignersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setFlowDesignerTappedId(''));
    dispatch(setFunctionalPageMode(PAGE_MODE_CREATE));
    dispatch(clearFlowDesignerApprovers(''));
    dispatch(reset(RXFORM_FLOW_DESIGNER));
  },
  getDataTable: (stepsId, dataTable, downloadDelete) => {
    if (dataTable.length > 0 && stepsId !== '' && !downloadDelete) {
      const filteredData = dataTable.filter((x) => ((x.stepId ? x.stepId : x.id) === stepsId));
      const approverData = filteredData.map((x) => {
        const data = x?.data || x?.approvers;
        const tableData = data.map((y, i) => ({
          tableData: {
            data: y?.data || y,
            approvers: getName(
              (y?.data?.type || y?.type) || '',
              (y?.data?.managerLevel || y?.managerLevel) || '0',
              (y?.data?.user?.fullName || y?.user?.fullName) || '-',
              (y?.data?.group?.name || y?.group?.name) || '-',
            ),
            no: i + 1,
          },
        }));

        return ({
          ...x,
          data: tableData,
        });
      });

      return approverData;
    }
    return [];
  },
  onViewEditPressed: () => {
    dispatch(setFunctionalPageMode(PAGE_MODE_EDIT));
    dispatch(downloadDeleteFlowDesignerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setFlowDesignerSelectedPageSize(pageSize));
    dispatch(clearFlowDesigners());
    dispatch(downloadFlowDesignersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearFlowDesigners());
    dispatch(setFlowDesignerAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setFlowDesignerSelectedOrderBy(INITIAL_ORDER_BY_FLOW_DESIGNERS));
    dispatch(downloadFlowDesignersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setFlowDesignerSearchText(text));
      dispatch(clearFlowDesigners());
      await dispatch(downloadFlowDesignersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setFlowDesignerSelectedOrderBy(orderBy));
    dispatch(clearFlowDesigners());
    dispatch(downloadFlowDesignersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitFlowDesignerPressed: async (value) => {
    try {
      await dispatch(addEditFlowDesignerAsync(value));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onViewPressed: (id) => {
    dispatch(setFlowDesignerTappedId(id));
    dispatch(downloadDeleteFlowDesignerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEditPressed: (id) => {
    dispatch(setFlowDesignerTappedId(id));
    dispatch(downloadDeleteFlowDesignerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setFlowDesignerTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteFlowDesignerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onTypeOptionSelected: (value, workflow, approversTable, field) => {
    dispatch(change(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_TYPE}`, value));
    dispatch(blur(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_TYPE}`));
    dispatch(setFlowDesignerSelectedType({ value, workflow }));
    clearFields(dispatch, value, workflow, approversTable, field);
  },
  onTypeValueChange: (value, previousValue, workflow) => {
    if (previousValue) {
      if (previousValue !== value && value !== '') {
        dispatch(change(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_TYPE}`, value));
        dispatch(blur(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_TYPE}`));
      } else {
        dispatch(change(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_TYPE}`, previousValue));
        dispatch(blur(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_TYPE}`));
      }
    } else {
      dispatch(change(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_TYPE}`, value));
      dispatch(blur(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_TYPE}`));
    }
  },
  onApproverModeOptionSelected: (value, workflow) => {
    dispatch(setFlowDesignerSelectedApproverMode(value));
    dispatch(change(RXFORM_FLOW_DESIGNER, `${workflow}.${RXFIELD_FLOW_DESIGNER_APPROVER_MODE}`, value));
  },
  onEditApproversButtonPressed: (stepId, dataTable) => {
    dispatch(setFlowDesignerApproversDialogVisibility(true));
    dispatch(setApproverTableSelectedStepId(stepId));
    dispatch(setApproverTableData(dataTable));
    if (dataTable.length > 0) {
      const convertDataTable = dataTable.reduce((p, c) => {
        const value = c?.data || c;
        return ({
          ...p,
          [value.id]: {
            data: value,
            id: value.id,
          },
        });
      }, {});
      dispatch(setApproverTableData(convertDataTable));
    }
  },
  onClosePressed: () => {
    dispatch(setFlowDesignerApproversDialogVisibility(false));
    dispatch(setApproverTableSelectedStepId(''));
  },
  onError: (error) => {
    dispatch(setAlertErrorMessage(error));
  },
  onDeleteProcessFlowPressed: (index) => {
    dispatch(setFlowDesignerSelectedProcessFlowIndex(index));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FlowDesignerPage);
