/* eslint-disable react/prop-types */
import React from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import {
  Button, Divider, Grid, IconButton, makeStyles, Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { SquareEditOutline } from 'mdi-material-ui';
import {
  AccentButton, AddApproversDialog, BasePage, FunctionalPage,
} from '../../component';
import {
  RXSTATE_FLOW_DESIGNER_PAGE, RXSTATE_FLOW_DESIGNERS,
  DragDropItemTypes, PAGE_MODE_VIEW, TYPE_DROPDOWN, APPROVER_MODE_DROPDOWN,
  RXFIELD_FLOW_DESIGNER_WORKFLOW, RXFIELD_FLOW_DESIGNER_STEP_NAME,
  RXFORM_FLOW_DESIGNER, RXFIELD_FLOW_DESIGNER_PROCESS_NAME,
  RXFIELD_FLOW_DESIGNER_DESCRIPTION, RXFIELD_FLOW_DESIGNER_TYPE,
  RXFIELD_FLOW_DESIGNER_MAX_LEVEL, RXFIELD_FLOW_DESIGNER_APPROVER_MODE,
  RXFIELD_FLOW_DESIGNER_APPROVERS, COLOR_DANGEROUS, COLOR_PRIMARY,
  COLOR_SECONDARY, COLOR_TEXT_BLACK_SECONDARY,
  DATE_TIME_FORMAT_WITHOUT_PIPE, PAGE_MODE_TABLE, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT, COLOR_TABLE_SECTION,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormDroppableField, renderReduxFormEditableTableField,
  renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../type';
import { rxformValidateFlowDesigner } from '../../validation';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  editDetailheaderField: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  primaryButtonContainer: {
    padding: '4px',
    marginRight: '12px',
  },
  primaryButton: {
    color: COLOR_PRIMARY,
    fontSize: '20px',
  },
  editDetailtitle: {
    color: COLOR_TEXT_BLACK_SECONDARY,
    marginRight: 35,
    fontWeight: 700,
    fontSize: 24,
  },
  form: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginTop: 10,
    marginBottom: -10,
  },
  buttonText: {
    color: COLOR_DANGEROUS,
  },
  formContentField: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginBottom: 10,
  },
  formContentButton: {
    alignSelf: 'flex-end',
    display: 'flex',
    flex: 1,
  },
  buttonRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  divider: {
    backgroundColor: COLOR_SECONDARY,
    marginBottom: 12,
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: COLOR_TABLE_SECTION,
    padding: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  table: {
    backgroundColor: COLOR_TABLE_SECTION,
    padding: 10,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  outlined: {
    color: COLOR_PRIMARY,
    borderRadius: 6,
    paddingLeft: 10,
    border: `1px ${COLOR_PRIMARY} solid`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  activityIndicator: {
    color: COLOR_SECONDARY,
  },
  ml20: {
    marginLeft: 20,
  },
}));


const renderDialog = (isOpen, onClosePressed) => (
  <AddApproversDialog
    dialogVisibility={isOpen}
    onCancelPressed={onClosePressed}
  />
);

const renderItem = (
  fieldSwapValue, initialValues, onTypeValueChange,
  workflow, index, pageMode, onTypeOptionSelected,
  onApproverModeOptionSelected, classes, addingEditingFlowDesigner,
  onEditApproversButtonPressed, approversTable, steps,
  getDataTable, downloadDeletingFlowDesigner,
) => {
  const dataTable = getDataTable(
    (steps[index]?.id || ''),
    approversTable,
    downloadDeletingFlowDesigner,
  );

  const getEditDataTable = () => {
    const array = approversTable.length > 0 ? approversTable : initialValues.workflow;
    const filteredArray = array.filter((x) => ((x.stepId ? x.stepId : x.id) === steps[index]?.id));
    return filteredArray[0]?.approvers || filteredArray[0]?.data || [];
  };

  const stepName = fieldSwapValue
    ? fieldSwapValue[index]?.stepName : '';
  const type = fieldSwapValue
    ? fieldSwapValue[index]?.type : '';

  const renderConditionalFields = () => {
    if (type === TYPE_DROPDOWN[1]) {
      return (
        <Grid item sm md>
          <Grid item>
            <Field
              name={`${workflow}.${RXFIELD_FLOW_DESIGNER_APPROVER_MODE}`}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.flowDesignerPage.labelApproverMode}
              label={LocalizedString.flowDesignerPage.labelApproverMode}
              disabled={pageMode === PAGE_MODE_VIEW || addingEditingFlowDesigner}
              data={APPROVER_MODE_DROPDOWN}
              onOptionSelected={(value) => onApproverModeOptionSelected(value, workflow)}
              required
              tooltipTitle={LocalizedString.flowDesignerPage.buttonCaptionTooltipApproverMode}
              useTooltip
            />
          </Grid>
        </Grid>
      );
    }
    if (type === TYPE_DROPDOWN[0]) {
      return (
        <Grid item sm md>
          <Grid item>
            <Field
              name={`${workflow}.${RXFIELD_FLOW_DESIGNER_MAX_LEVEL}`}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.flowDesignerPage.labelMaxLevel}
              label={LocalizedString.flowDesignerPage.labelMaxLevel}
              disabled={pageMode === PAGE_MODE_VIEW || addingEditingFlowDesigner}
              type="number"
              required
            />
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const renderTable = () => (
    type === TYPE_DROPDOWN[1]
    && (
      <Grid item sm={12}>
        <div
          className={classes.tableContainer}
        >
          <div className={classes.titleContainer}>
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              {LocalizedString.flowDesignerPage.labelApprovers}
            </Typography>
          </div>
          {
            pageMode !== PAGE_MODE_VIEW && (
            <div className={classes.buttonContainer}>
              <Button
                variant="outlined"
                className={classes.outlined}
                size="medium"
                onClick={() => onEditApproversButtonPressed(
                  (steps[index]?.id || ''),
                  getEditDataTable(),
                )}
                startIcon={<SquareEditOutline />}
                hidden={pageMode === PAGE_MODE_VIEW || addingEditingFlowDesigner}
              >
                {GlobalLocalizedString.common.buttonCaptionEdit}
              </Button>
            </div>
            )
}
        </div>
        <div className={classes.table}>
          <Field
            name={`${workflow}.${RXFIELD_FLOW_DESIGNER_APPROVERS}`}
            component={renderReduxFormEditableTableField}
            data={dataTable[0]?.data}
            label={LocalizedString.flowDesignerPage.labelApprovers}
            disabled
            disableEdit
            disableDelete
            disableToolbar
            tableColumns={[
              {
                title: LocalizedString.flowDesignerPage.labelNo, field: 'tableData.no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
              },
              { title: LocalizedString.flowDesignerPage.labelApprovers, field: 'tableData.approvers', sorting: false },
              {
                title: LocalizedString.flowDesignerPage.labelType, field: 'tableData.data.type', sorting: false,
              },
            ]}
          />
        </div>
      </Grid>
    )
  );

  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={`${workflow}.${RXFIELD_FLOW_DESIGNER_STEP_NAME}`}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.flowDesignerPage.labelStepName}
              label={LocalizedString.flowDesignerPage.labelStepName}
              disabled={pageMode === PAGE_MODE_VIEW || addingEditingFlowDesigner}
              defaultValue={stepName}
              required
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={`${workflow}.${RXFIELD_FLOW_DESIGNER_TYPE}`}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.flowDesignerPage.labelType}
              label={LocalizedString.flowDesignerPage.labelType}
              disabled={pageMode === PAGE_MODE_VIEW || addingEditingFlowDesigner}
              data={TYPE_DROPDOWN}
              defaultValue={type}
              onOptionSelected={(value) => onTypeOptionSelected(
                value, workflow, approversTable, fieldSwapValue,
              )}
              required
            />
          </Grid>
        </Grid>
        { renderConditionalFields() }
        { renderTable(workflow) }
      </Grid>
    </Grid>
  );
};

const FlowDesignerPage = ({
  onAppear, downloading, onCancelPressed, onChangePage, onChangePageSize,
  onRefresh, onSearchBarTextChanged, fieldSwapValue, onSortPressed,
  onViewPressed, handleSubmit, pageMode, onTypeValueChange, onCreatePressed,
  onBackPressed, initialValues, onTypeOptionSelected,
  onApproverModeOptionSelected, onClosePressed, onEditApproversButtonPressed,
  isOpen, approversTable, steps, getDataTable, onSubmitFlowDesignerPressed,
  downloadDeletingFlowDesigner, tappedId, onViewEditPressed, onEditPressed, onDeletePressed,
  onConfirmDeletePressed, onError, onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  onDeleteProcessFlowPressed, emptyFormValue, addingEditingFlowDesigner,
}) => {
  const classes = useStyles();

  const onHandleSubmit = async (e) => {
    try {
      await onSubmitFlowDesignerPressed(e);
      onClosePressed();
    } catch (error) {
      onError(error);
    }
  };

  if (pageMode === PAGE_MODE_TABLE) {
    return (
      <FunctionalPage
        data={RXSTATE_FLOW_DESIGNERS}
        uiPage={RXSTATE_FLOW_DESIGNER_PAGE}
        tableColumns={[
          {
            title: LocalizedString.flowDesignerPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.flowDesignerPage.labelProcessName, field: 'processName', sorting: !downloading },
          { title: LocalizedString.flowDesignerPage.labelDescription, field: 'description', sorting: !downloading },
          {
            title: LocalizedString.flowDesignerPage.labelCreatedDate,
            field: 'createdDate',
            sorting: !downloading,
            render: ({ createdDate }) => (createdDate
              ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
          },
        ]}
        filterColumns={[
          {
            title: LocalizedString.approvalGroupPage.labelCreatedDate,
            field: 'createdDate',
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_FORMAT,
          },
        ]}
        onAppear={onAppear}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onCreatePressed={onCreatePressed}
        onRefresh={onRefresh}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onViewPressed={onViewPressed}
        onEditPressed={onEditPressed}
        onDeletePressed={onDeletePressed}
        onConfirmDeletePressed={onConfirmDeletePressed}
        useFullWidth
        title={LocalizedString.flowDesignerPage.title}
        useTwoColumnFilterDialog
        createNewButtonCaption={LocalizedString.flowDesignerPage.buttonCaptionCreateNewProcess}
        editButtonCaption={LocalizedString.flowDesignerPage.buttonCaptionEditProcessFlow}
        deleteButtonCaption={LocalizedString.flowDesignerPage.buttonCaptionDeleteProcessFlow}
      >
        <div />
      </FunctionalPage>
    );
  }

  return (
    <BasePage onConfirmPressed={onConfirmDeletePressed}>
      <div className={classes.editDetailheaderField}>
        <IconButton onClick={onBackPressed} className={classes.primaryButtonContainer}>
          <ArrowBack className={classes.primaryButton} />
        </IconButton>
        <Typography className={classes.editDetailtitle}>
          {LocalizedString.flowDesignerPage.title}
        </Typography>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.container}>
        <div className={classes.formContainer}>
          <form
            onSubmit={handleSubmit(onHandleSubmit)}
            className={classes.form}
            id="data"
          >
            <div className={classes.formContentField}>
              <Field
                name={RXFIELD_FLOW_DESIGNER_PROCESS_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.flowDesignerPage.labelProcessName}
                label={LocalizedString.flowDesignerPage.labelProcessName}
                disabled={pageMode === PAGE_MODE_VIEW}
                required
              />
              <Field
                name={RXFIELD_FLOW_DESIGNER_DESCRIPTION}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.flowDesignerPage.labelDescription}
                label={LocalizedString.flowDesignerPage.labelDescription}
                disabled={pageMode === PAGE_MODE_VIEW}
                multiline
                rows={3}
              />
              <FieldArray
                name={RXFIELD_FLOW_DESIGNER_WORKFLOW}
                component={renderReduxFormDroppableField}
                label={LocalizedString.flowDesignerPage.labelStep}
                title={LocalizedString.flowDesignerPage.labelProcessFlow}
                deleteButtonCaption={
                  LocalizedString.flowDesignerPage.buttonCaptionDeleteProcessFlow
                }
                hideDeleteButton={pageMode === PAGE_MODE_VIEW || addingEditingFlowDesigner}
                addButton={pageMode !== PAGE_MODE_VIEW}
                addButtonCaption={LocalizedString.flowDesignerPage.buttonCaptionAddProcessFlow}
                deleteButtonPressed={(index) => onDeleteProcessFlowPressed(index)}
                disabled={pageMode === PAGE_MODE_VIEW}
                type={DragDropItemTypes}
                renderItem={(workflow, index) => renderItem(
                  fieldSwapValue, initialValues, onTypeValueChange,
                  workflow, index, pageMode, onTypeOptionSelected,
                  onApproverModeOptionSelected, classes, addingEditingFlowDesigner,
                  onEditApproversButtonPressed, approversTable, steps,
                  getDataTable, downloadDeletingFlowDesigner,
                )}
                required
              />

            </div>

            {renderDialog(
              isOpen, onClosePressed,
            )}

            <div className={classes.formContentButton}>
              {
                      pageMode !== PAGE_MODE_VIEW
                        && (
                          <div className={classes.buttonRowContainer}>
                            <AccentButton
                              onClick={onCancelPressed}
                              variant="text"
                              caption={GlobalLocalizedString.common.buttonCaptionCancel}
                              className={classes.buttonText}
                              disabled={addingEditingFlowDesigner}
                              size="small"
                            />

                            <AccentButton
                              variant="contained"
                              type="submit"
                              loading={addingEditingFlowDesigner}
                              disableElevation
                              caption={GlobalLocalizedString.common.buttonCaptionSave}
                              disabled={emptyFormValue}
                              size="small"
                              style={classes.ml20}
                            />
                          </div>
                        )
                    }

              {
                      pageMode === PAGE_MODE_VIEW
                        && (
                          <div className={classes.buttonRowContainer}>
                            <AccentButton
                              onClick={() => onDeletePressed(tappedId)}
                              variant="text"
                              caption={
                                  LocalizedString.flowDesignerPage.buttonCaptionDeleteFlowDesigner
                                }
                              disableElevation
                              className={classes.buttonText}
                              disabled={pageMode !== PAGE_MODE_VIEW}
                              loading={downloadDeletingFlowDesigner}
                              size="small"
                            />
                            <AccentButton
                              disabled={pageMode !== PAGE_MODE_VIEW || downloadDeletingFlowDesigner}
                              disableElevation
                              caption={
                                LocalizedString.flowDesignerPage.buttonCaptionEditFlowDesigner
                              }
                              style={classes.ml20}
                              onClick={() => onViewEditPressed(tappedId)}
                              size="small"
                            />
                          </div>
                        )
                    }
            </div>
          </form>
        </div>
      </div>
    </BasePage>
  );
};
export default reduxForm({
  form: RXFORM_FLOW_DESIGNER,
  validate: rxformValidateFlowDesigner,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  shouldValidate: () => true,
})(FlowDesignerPage);

FlowDesignerPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  fieldSwapValue: PropTypes.arrayOf(PropTypes.object).isRequired,
  approversTable: PropTypes.arrayOf(PropTypes.object).isRequired,

  downloading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  downloadDeletingFlowDesigner: PropTypes.bool.isRequired,
  emptyFormValue: PropTypes.bool.isRequired,
  addingEditingFlowDesigner: PropTypes.bool.isRequired,

  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,

  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onBackPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeleteProcessFlowPressed: PropTypes.func.isRequired,
  getDataTable: PropTypes.func.isRequired,
  onClosePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onTypeOptionSelected: PropTypes.func.isRequired,
  onTypeValueChange: PropTypes.func.isRequired,
  onApproverModeOptionSelected: PropTypes.func.isRequired,
  onEditApproversButtonPressed: PropTypes.func.isRequired,
  onSubmitFlowDesignerPressed: PropTypes.func.isRequired,
  onViewEditPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
