import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, withStyles,
} from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import LocalizedString from '../localization';
import { COLOR_ICON } from '../constant';
import AccentButton from './accent-button';

const styles = (() => ({
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  closeHeaderButton: {
    color: COLOR_ICON,
    marginRight: 20,
  },
  buttonContainer: {
    justifyContent: 'flex-end',
  },
  title: {
    '& .MuiTypography-h6': {
      fontSize: '14px',
    },
    padding: '16px 24px 8px 24px',
    color: COLOR_ICON,
  },
}));

const StyledDialog = ({
  disabled, disableSubmitButton, submitting, visibility,
  onCancelPressed, onSubmitPressed, hideButton,
  classes, children,
  headerContainerStyle, title, submitButtonCaption,
}) => (
  <Dialog
    open={visibility}
    aria-labelledby="form-dialog-title"
    maxWidth="lg"
    fullWidth
    classes={{ paper: classes.paper }}
  >
    <div className={`${classes.headerContainer} ${headerContainerStyle}`}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <IconButton
        onClick={onCancelPressed}
        disabled={disabled || submitting}
        size="small"
        className={classes.closeHeaderButton}
      >
        <Close fontSize="inherit" />
      </IconButton>
    </div>

    <DialogContent>
      {children}

      {
        !hideButton && (
        <DialogActions className={classes.buttonContainer}>
          <AccentButton
            onClick={onCancelPressed}
            variant="outlined"
            caption={LocalizedString.common.buttonCaptionCancel}
            disabled={disabled || submitting}
            size="small"
          />

          <AccentButton
            onClick={onSubmitPressed}
            variant="contained"
            loading={submitting}
            disableElevation
            caption={submitButtonCaption || LocalizedString.common.buttonCaptionSave}
            disabled={disableSubmitButton || disabled}
            size="small"
          />
        </DialogActions>
        )
      }
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(StyledDialog);

StyledDialog.propTypes = {
  disabled: PropTypes.bool,
  disableSubmitButton: PropTypes.bool,
  submitting: PropTypes.bool,
  hideButton: PropTypes.bool,
  visibility: PropTypes.bool.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  headerContainerStyle: PropTypes.string,
  submitButtonCaption: PropTypes.string,
  title: PropTypes.string.isRequired,
};

StyledDialog.defaultProps = {
  disabled: false,
  disableSubmitButton: false,
  submitting: false,
  hideButton: false,
  children: (<div />),
  headerContainerStyle: '',
  submitButtonCaption: '',
};
