import {
  SET_ADDING_APPROVER_TABLE, SET_APPROVER_TABLE_DATA, SET_APPROVER_TABLE_SELECTED_GROUP_MODE,
  SET_APPROVER_TABLE_SELECTED_GROUP_NAME, SET_APPROVER_TABLE_SELECTED_STEP_ID,
  SET_APPROVER_TABLE_SELECTED_LEVEL, SET_APPROVER_TABLE_SELECTED_TYPE,
  SET_APPROVER_TABLE_SELECTED_USER, CLEAR_APPROVER_TABLE_DATA,
  ADD_APPROVER_TABLE_DATA, SET_APPROVER_TABLE_SELECTED_TABLE_DATA_ID,
} from '../action';

const initialState = {
  data: {},
  selectedStepId: '',
  selectedType: '',
  selectedUser: '',
  selectedGroupName: '',
  selectedGroupMode: '',
  selectedLevel: '',
  selectedTableDataId: '',
  adding: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ADDING_APPROVER_TABLE:
      return { ...state, adding: action.status };
    case SET_APPROVER_TABLE_DATA: {
      return {
        ...state,
        data: action.data,
      };
    }
    case ADD_APPROVER_TABLE_DATA: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case CLEAR_APPROVER_TABLE_DATA: {
      return {
        ...state,
        data: initialState.data,
      };
    }
    case SET_APPROVER_TABLE_SELECTED_STEP_ID:
      return { ...state, selectedStepId: action.index };
    case SET_APPROVER_TABLE_SELECTED_TABLE_DATA_ID:
      return { ...state, selectedTableDataId: action.index };
    case SET_APPROVER_TABLE_SELECTED_TYPE:
      return { ...state, selectedType: action.option };
    case SET_APPROVER_TABLE_SELECTED_USER:
      return { ...state, selectedUser: action.option };
    case SET_APPROVER_TABLE_SELECTED_GROUP_NAME:
      return { ...state, selectedGroupName: action.option };
    case SET_APPROVER_TABLE_SELECTED_GROUP_MODE:
      return { ...state, selectedGroupMode: action.option };
    case SET_APPROVER_TABLE_SELECTED_LEVEL:
      return { ...state, selectedLevel: action.option };
    default: return state;
  }
};
