import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './reducer';
import { IS_PRODUCTION } from '../constant';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication', 'currentUser'],
  blacklist: [
    'form',
    'uiAchievement',
    'uiActivity',
    'uiActivityType',
    'uiAdditionalField',
    'uiAnnotationDesigner',
    'uiApiKey',
    'uiApplication',
    'uiApproval',
    'uiApprovalGroup',
    'uiApprovalTable',
    'uiAsset',
    'uiAssetCertificate',
    'uiAssetChild',
    'uiAssetDocument',
    'uiAssetScreening',
    'uiAttendanceSummary',
    'uiAuditTrail',
    'uiAuthPasswordResetAttempt',
    'uiAuthRegistrationAttempt',
    'uiBanner',
    'uiBasePage',
    'uiBranch',
    'uiCaptchaRequest',
    'uiCertificationMyApproval',
    'uiCertificationRequest',
    'uiCertificationScheduleSetting',
    'uiCertificationType',
    'uiChallenge',
    'uiCity',
    'uiColorPickerField',
    'uiCompany',
    'uiCompanyMapping',
    'uiCompletedChallenge',
    'uiConfigAction',
    'uiConfigGroup',
    'uiConfigItem',
    'uiConfigValue',
    'uiCustomer',
    'uiDailyCheckin',
    'uiDiscussion',
    'uiDiscussionComment',
    'uiDistrict',
    'uiDroppableField',
    'uiDocument',
    'uiDynamicForm',
    'uiEditableTableField',
    'uiEmail',
    'uiEvent',
    'uiEventCategory',
    'uiEventInvitation',
    'uiEventOperatorEvent',
    'uiEventOperatorInvitation',
    'uiEventOperatorMapping',
    'uiEventOperatorRegistration',
    'uiEventRegistration',
    'uiFAQ',
    'uiFAQCategory',
    'uiField',
    'uiFlowDesigner',
    'uiForm',
    'uiForumCategory',
    'uiForumPost',
    'uiFunctionalPage',
    'uiGameQRCategory',
    'uiGiftVoucherRedemption',
    'uiHistory',
    'uiImagePickerDialog',
    'uiInbox',
    'uiIndustry',
    'uiInfoMapping',
    'uiJob',
    'uiLeaderBoard',
    'uiLocation',
    'uiLogin',
    'uiMyJob',
    'uiNews',
    'uiNewsCategory',
    'uiNotification',
    'uiNotificationTask',
    'uiPacket',
    'uiPacketSummary',
    'uiPacketType',
    'uiPartnerMerchant',
    'uiPayment',
    'uiPdfList',
    'uiPermission',
    'uiPickupLocation',
    'uiPoint',
    'uiPointSubmission',
    'uiPointTransaction',
    'uiPopUpBanner',
    'uiPreview',
    'uiProfile',
    'uiProfileEditData',
    'uiProfileSync',
    'uiPromotedMerchant',
    'uiProvince',
    'uiPurchaseRequest',
    'uiPushNotification',
    'uiRegistrationReferral',
    'uiReportedComment',
    'uiResponse',
    'uiRole',
    'uiRoom',
    'uiScannedCode',
    'uiScope',
    'uiService',
    'uiSession',
    'uiSessionCategory',
    'uiSessionInvitation',
    'uiSessionOperatorInvitation',
    'uiSessionOperatorMapping',
    'uiSessionOperatorRegistration',
    'uiSessionOperatorSession',
    'uiSessionRegistration',
    'uiShortlink',
    'uiSms',
    'uiSpeaker',
    'uiSponsor',
    'uiSponsorCategory',
    'uiSubdistrict',
    'uiSummary',
    'uiTemplate',
    'uiToken',
    'uiTokenImport',
    'uiTokenRequest',
    'uiUploadPdf',
    'uiUser',
    'uiVoidPointRequest',
    'uiVoucher',
    'uiVoucherCategory',
    'uiVoucherPurchaseRequest',
    'uiVoucherToken',
    'uiVoucherTokenAssignment',
    'uiVoucherUsage',
    'uiWhatsapp',
    'uiWorkingShift',
  ],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = IS_PRODUCTION
  ? createStore(persistedReducer, applyMiddleware(thunk))
  : createStore(persistedReducer, applyMiddleware(thunk, logger));
export const persistor = persistStore(store);
