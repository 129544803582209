import { connect } from 'react-redux';
import { reset, change, untouch } from 'redux-form';
import {
  PAGE_MODE_TABLE, RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_TYPE,
  RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_USER, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_MODE,
  RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_NAME, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_LEVEL,
  TYPE_APPROVER_TABLE_MANAGER_LEVEL, TYPE_APPROVER_TABLE_USER, TYPE_APPROVER_TABLE_GROUP,
} from '../../constant';
import {
  debounceSearch, transformUserDropdownData, transformDropdownData, getName,
} from '../../helper';
import AddApproversDialog from './add-approvers-dialog.presentation';
import {
  setAlertErrorMessage, setFunctionalPageMode, addEditApprovers,
  setAddingApproverTable, setApproverTableSelectedType, setApproverTableSelectedUser,
  clearApprovalGroups, downloadApprovalGroupsAsync,
  setApprovalGroupAdvancedFilterDialogSelectedFilterString,
  setApprovalGroupSearchText, setApproverTableSelectedGroupName,
  setApproverTableSelectedGroupMode, setApproverTableData, clearApproverTableData,
  setApproverTableSelectedLevel, setApproverTableSelectedTableDataId,
  setFlowDesignerApprovers, setFlowDesignerApproversDialogVisibility,
  setApproverTableSelectedStepId,
} from '../../redux/action';
import {
  setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText, clearProfiles, downloadProfilesAsync,
} from '../../../../redux/action';
import LocalizedString from '../../localization';

const getManagerLevel = (type, level) => {
  switch (type) {
    case TYPE_APPROVER_TABLE_MANAGER_LEVEL:
      return level;
    case TYPE_APPROVER_TABLE_USER:
      return LocalizedString.flowDesignerPage.labelUser;
    case TYPE_APPROVER_TABLE_GROUP:
      return '-';
    default: return '-';
  }
};

const getApproversTableData = (state) => {
  const {
    data,
  } = state.uiApproverTable;

  const arrData = Object.values(data);

  return arrData.length > 0 ? arrData.map((item, i) => ({
    ...item,
    no: i + 1,
    approverTableUserName: getName(
      item?.data?.type,
      item?.data?.managerLevel,
      item?.data?.user?.fullName,
      item?.data?.group?.name,
    ),
    approverTableType: item?.data?.type || '-',
    approverTableGroupMode: item?.data?.groupMode || '-',
    approverTableManagerLevel: getManagerLevel(
      item?.data?.type,
      item?.data?.managerLevel,
    ),
  })) : [];
};

const getValidationSave = (state) => {
  const { uiApproverTable } = state;
  const {
    selectedType, selectedUser,
    selectedGroupMode, selectedGroupName,
    selectedLevel,
  } = uiApproverTable;

  if (selectedType !== '') {
    switch (selectedType) {
      case TYPE_APPROVER_TABLE_MANAGER_LEVEL:
        return selectedLevel === '' || !selectedLevel;
      case TYPE_APPROVER_TABLE_USER:
        return (selectedUser === '' || !selectedUser);
      case TYPE_APPROVER_TABLE_GROUP:
        return selectedGroupMode === '' || selectedGroupName === '' || !selectedGroupMode || !selectedGroupName;
      default: return false;
    }
  }
  return true;
};

const mapStateToProps = (state) => ({
  adding: state.uiApproverTable.adding,
  addingTable: state.uiFlowDesigner.addingApprovers,
  approverTable: getApproversTableData(state),
  users: transformUserDropdownData(state.profiles.data),
  groupName: transformDropdownData(state.approvalGroups.data),
  loadingUser: state.uiProfile.downloading,
  loadingGroupName: state.uiApprovalGroup.downloading,
  selectedType: state.uiApproverTable.selectedType,
  stepId: state.uiApproverTable.selectedStepId,
  pageMode: state.uiFunctionalPage.pageMode,
  disableSave: getValidationSave(state),
});

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const searchApprovalGroupDebounce = debounceSearch((dispatch) => {
  dispatch(clearApprovalGroups());
  dispatch(downloadApprovalGroupsAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const clearFields = (dispatch) => {
  dispatch(setApproverTableSelectedGroupMode(''));
  dispatch(change(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_MODE, ''));
  dispatch(untouch(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_MODE));
  dispatch(setApproverTableSelectedGroupName(''));
  dispatch(change(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_NAME, ''));
  dispatch(untouch(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_NAME));
  dispatch(setApproverTableSelectedLevel(''));
  dispatch(change(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_LEVEL, ''));
  dispatch(untouch(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_LEVEL));
  dispatch(setApproverTableSelectedUser(''));
  dispatch(change(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_USER, ''));
  dispatch(untouch(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_USER));
};

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setApprovalGroupAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setApprovalGroupSearchText(''));
    dispatch(clearApprovalGroups());
    dispatch(downloadApprovalGroupsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAddTablePressed: () => {
    dispatch(setAddingApproverTable(true));
    dispatch(setApproverTableSelectedTableDataId(''));
  },
  onBackPressed: () => {
    dispatch(reset(RXFORM_APPROVER_TABLE));
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
  },
  onCancelAddPressed: () => {
    dispatch(setAddingApproverTable(false));
    dispatch(setApproverTableSelectedType(''));
    dispatch(setApproverTableSelectedUser(''));
    dispatch(setApproverTableSelectedGroupName(''));
    dispatch(setApproverTableSelectedGroupMode(''));
    dispatch(setApproverTableSelectedLevel(''));
    dispatch(reset(RXFORM_APPROVER_TABLE));
  },
  onSaveAddApproverPressed: () => {
    dispatch(addEditApprovers());
  },
  onTypeOptionSelected: (option) => {
    dispatch(setApproverTableSelectedType(option));
    dispatch(change(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_TYPE, option));
    clearFields(dispatch);

    if (option === TYPE_APPROVER_TABLE_USER) {
      dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setProfileSearchText(''));
      dispatch(clearProfiles());
      dispatch(downloadProfilesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    }
    if (option === TYPE_APPROVER_TABLE_GROUP) {
      dispatch(setApprovalGroupAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setApprovalGroupSearchText(''));
      dispatch(clearApprovalGroups());
      dispatch(downloadApprovalGroupsAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onChangeUserText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onChangeGroupNameText: async (text) => {
    dispatch(setApprovalGroupAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setApprovalGroupSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchApprovalGroupDebounce(dispatch);
    }
  },
  onChangeManagerLevelText: (text) => {
    dispatch(setApproverTableSelectedLevel(text.target.value));
  },
  onUserOptionSelected: (option) => {
    dispatch(setApproverTableSelectedUser(option));
    dispatch(change(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_USER, option));
  },
  onGroupNameOptionSelected: (option) => {
    dispatch(setApproverTableSelectedGroupName(option));
    dispatch(change(
      RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_NAME, option,
    ));
  },
  onGroupModeOptionSelected: (option) => {
    dispatch(setApproverTableSelectedGroupMode(option));
    dispatch(change(
      RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_MODE, option,
    ));
  },
  onSubmitPressed: (selectedAssets, stepId) => {
    const arr = selectedAssets.map((x) => ({
      data: {
        ...x.data,
        no: x.no,
        id: x.id,
      },
    }));
    const body = {
      data: arr,
      stepId,
    };
    try {
      dispatch(setFlowDesignerApprovers(body));
      dispatch(clearApproverTableData());
      dispatch(setFlowDesignerApproversDialogVisibility(false));
      dispatch(setApproverTableSelectedStepId(''));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onEditTablePressed: (id, data) => {
    const {
      user, managerLevel, group, groupMode, type,
    } = data;

    dispatch(setApproverTableSelectedTableDataId(id));

    switch (type) {
      case TYPE_APPROVER_TABLE_USER:
        dispatch(setApproverTableSelectedType(type));
        dispatch(change(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_TYPE, type));
        dispatch(setApproverTableSelectedUser({ label: user.fullName, value: user.id }));
        dispatch(change(
          RXFORM_APPROVER_TABLE,
          RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_USER,
          { label: user.fullName, value: user.id },
        ));
        break;
      case TYPE_APPROVER_TABLE_MANAGER_LEVEL:
        dispatch(setApproverTableSelectedType(type));
        dispatch(change(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_TYPE, type));
        dispatch(setApproverTableSelectedLevel(managerLevel));
        dispatch(
          change(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_LEVEL, managerLevel),
        );
        break;
      case TYPE_APPROVER_TABLE_GROUP:
        dispatch(setApproverTableSelectedType(type));
        dispatch(change(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_TYPE, type));
        dispatch(setApproverTableSelectedGroupName({ label: group.name, value: group.id }));
        dispatch(
          change(
            RXFORM_APPROVER_TABLE,
            RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_NAME,
            { label: group.name, value: group.id },
          ),
        );
        dispatch(setApproverTableSelectedGroupMode(groupMode));
        dispatch(
          change(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_MODE, groupMode),
        );
        break;
      default: break;
    }

    dispatch(setAddingApproverTable(true));
  },
  onDeleteTablePressed: (dataTable, data) => {
    const findIndex = dataTable.findIndex((x) => x.id === data.id);
    dataTable.splice(findIndex, 1);
    if (dataTable.length > 0) {
      const convertDataTable = dataTable.reduce((p, c) => ({
        ...p,
        [c.id]: {
          data: c.data,
          id: c.id,
        },
      }), {});
      dispatch(setApproverTableData(convertDataTable));
    } else {
      dispatch(setApproverTableData({}));
      dispatch(change(RXFORM_APPROVER_TABLE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_USER, []));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddApproversDialog);
