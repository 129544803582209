import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_APPROVAL_GROUP_NAME, RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS,
  RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_TYPE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_USER,
  RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_LEVEL, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_NAME,
  RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_MODE, RXFIELD_FLOW_DESIGNER_PROCESS_NAME,
  TYPE_DIRECT_MANAGER, TYPE_REGULAR,
} from './constant';
import LocalizedString from './localization';
import GLobalizedString from '../../localization';

export const rxformValidateApprovalGroup = (values) => {
  const requiredFields = [
    RXFIELD_APPROVAL_GROUP_NAME,
    RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateApprovalTable = (values) => {
  const requiredFields = [
    RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_TYPE,
    RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_USER,
    RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_LEVEL,
    RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_NAME,
    RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_MODE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateFlowDesigner = (values, props) => {
  let errors = {};

  const requiredFields = [
    RXFIELD_FLOW_DESIGNER_PROCESS_NAME,
  ];

  errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values?.workflow && !values?.workflow?.length) {
    errors.workflow = { _error: LocalizedString.flowDesignerPage.errorProcessFlowMustNotBeEmpty };
  } else {
    const workflowsArrayErrors = [];

    if (values.workflow) {
      values.workflow.forEach((data, index) => {
        const workflowErrors = {};

        if (!data || !data.stepName) {
          workflowErrors.stepName = GLobalizedString.common.errMsgRequired;
          workflowsArrayErrors[index] = workflowErrors;
        }

        if (!data || !data.type) {
          workflowErrors.type = GLobalizedString.common.errMsgRequired;
          workflowsArrayErrors[index] = workflowErrors;
        }

        if (!data || !data.type) {
          workflowErrors.type = GLobalizedString.common.errMsgRequired;
          workflowsArrayErrors[index] = workflowErrors;
        }

        if ((!data || !data.maxLevel)
          && (values && values.workflow[index]?.type === TYPE_DIRECT_MANAGER)) {
          workflowErrors.maxLevel = GLobalizedString.common.errMsgRequired;
          workflowsArrayErrors[index] = workflowErrors;
        }

        if ((!data || !data.approverMode)
          && (values && values.workflow[index]?.type === TYPE_REGULAR)) {
          workflowErrors.approverMode = GLobalizedString.common.errMsgRequired;
          workflowsArrayErrors[index] = workflowErrors;
        }

        if ((!props || !props.approversTable.length)
          && (values && values.workflow[index]?.type === TYPE_REGULAR)) {
          workflowErrors.approvers = GLobalizedString.common.errMsgRequired;
          workflowsArrayErrors[index] = workflowErrors;
        }

        if ((!props || props.approversTable.length)
          && (data && data?.type === TYPE_REGULAR)) {
          const indexData = props?.approversTable.findIndex((x) => x.stepId === data.id);
          if (!props?.approversTable[indexData]?.data.length
            || !props?.approversTable[indexData]?.data.length === 0) {
            workflowErrors.approvers = GLobalizedString.common.errMsgRequired;
            workflowsArrayErrors[index] = workflowErrors;
          }
        }
      });
      if (workflowsArrayErrors.length) {
        errors.workflow = workflowsArrayErrors;
      }
    }
  }

  return errors;
};
