import { downloadingFlowDesigners, setFlowDesigners } from '../simple-action';
import { downloadFlowDesigners, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, flowDesigners, uiFlowDesigner,
  } = getState();
  const { token } = authentication;
  const { meta } = flowDesigners;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiFlowDesigner;

  try {
    dispatch(downloadingFlowDesigners(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadFlowDesigners(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setFlowDesigners(list));
    }
  } finally {
    dispatch(downloadingFlowDesigners(false));
  }
};
