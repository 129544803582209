import { downloadingApprovalGroups, setApprovalGroups } from '../simple-action';
import { downloadApprovalGroups, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, approvalGroups, uiApprovalGroup,
  } = getState();
  const { token } = authentication;
  const { meta } = approvalGroups;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiApprovalGroup;

  try {
    dispatch(downloadingApprovalGroups(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadApprovalGroups(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setApprovalGroups(list));
    }
  } finally {
    dispatch(downloadingApprovalGroups(false));
  }
};
