import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, FormControl, Button, Typography,
} from '@material-ui/core';
import { Drag, TrashCanOutline } from 'mdi-material-ui';
import { useDrag, useDrop } from 'react-dnd';
import { useHistory } from 'react-router-dom';
import {
  COLOR_SECONDARY, COLOR_FFF5F8, COLOR_REJECTED, COLOR_TABLE_SECTION, PAGE_MODE_VIEW,
} from '../../constant';
import LocalizedString from '../../localization';
import AlertBox from '../alert-box';

const useStyles = makeStyles(() => ({
  card: {
    border: `1px solid ${COLOR_SECONDARY}`,
    marginBottom: '10px',
    backgroundColor: 'white',
    cursor: 'move',
    borderRadius: 10,
  },
  form: {
    display: 'flex',
    padding: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: COLOR_TABLE_SECTION,
    borderBottom: `1px solid ${COLOR_SECONDARY}`,
    padding: 10,
    marginBottom: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  outlined: {
    color: COLOR_REJECTED,
    backgroundColor: COLOR_FFF5F8,
    borderRadius: 6,
    paddingLeft: 10,
    border: `1px ${COLOR_REJECTED} solid`,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const DraggableField = ({
  workflow, index, moveCard, type, renderItem, fields, onEndDrag,
  error, label, onDeleteButtonPressed, myConfigItems,
  deleteButtonCaption, hideDeleteButton, onConfirmDeleteButtonPressed,
  onAlertConfirm, pageMode, selectedProcessFlowIndex,
}) => {
  const ref = useRef(null);
  const formRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    onEndDrag(fields);
  }, [fields, onEndDrag]);

  const [{ handlerId }, drop] = useDrop({
    accept: type.FIELD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: type.FIELD,
    item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => onEndDrag(fields),
  });

  const containerProps = {
    opacity: isDragging ? 0 : 1,
  };

  const classes = useStyles(containerProps);

  drag(drop(ref));
  drag(formRef);

  return (
    <div
      ref={formRef}
      className={classes.card}
      data-handler-id={handlerId}
    >
      <div
        className={classes.container}
        ref={ref}
      >
        <div className={classes.titleContainer}>
          <Drag />
          <Typography style={{ fontSize: 14, fontWeight: 500 }}>
            {`${label} ${index + 1}`}
          </Typography>
        </div>
        {
        !hideDeleteButton && (
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            className={classes.outlined}
            size="medium"
            onClick={() => onDeleteButtonPressed(index)}
            startIcon={<TrashCanOutline />}
          >
            {deleteButtonCaption || LocalizedString.common.buttonCaptionRemove}
          </Button>
        </div>
        )
      }
        {
        pageMode !== PAGE_MODE_VIEW
        && (
        <AlertBox
          onConfirmPressed={
            (message) => onAlertConfirm(
              message, history, myConfigItems, onConfirmDeleteButtonPressed,
              selectedProcessFlowIndex,
            )
          }
        />
        )
      }
      </div>
      <FormControl className={classes.form} error={error}>
        {renderItem(workflow, index)}
      </FormControl>
    </div>
  );
};

export default DraggableField;

DraggableField.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.object.isRequired,
  myConfigItems: PropTypes.object.isRequired,

  error: PropTypes.bool,
  hideDeleteButton: PropTypes.bool,

  label: PropTypes.string,
  deleteButtonCaption: PropTypes.string,
  workflow: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,

  index: PropTypes.number.isRequired,
  selectedProcessFlowIndex: PropTypes.number.isRequired,

  moveCard: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  onEndDrag: PropTypes.func.isRequired,
  onDeleteButtonPressed: PropTypes.func,
  onConfirmDeleteButtonPressed: PropTypes.func,
  onAlertConfirm: PropTypes.func.isRequired,
};

DraggableField.defaultProps = {
  error: false,
  hideDeleteButton: false,
  label: '',
  deleteButtonCaption: '',
  onDeleteButtonPressed: () => {},
  onConfirmDeleteButtonPressed: () => {},
};
