import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, makeStyles, FormControl, FormHelperText,
} from '@material-ui/core';
import { Plus } from 'mdi-material-ui';
import {
  BUTTON_TYPE_TEXT, COLOR_BACKGROUND, COLOR_EEF1FE, COLOR_PRIMARY, COLOR_SECONDARY,
} from '../constant';
import AccentButton from './accent-button';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    margin: '10px 5px',
  },
  innerContainer: {
    backgroundColor: COLOR_BACKGROUND,
    border: `1px solid ${COLOR_SECONDARY}`,
    borderRadius: 10,
  },
  titleContainer: {
    backgroundColor: COLOR_EEF1FE,
    padding: 20,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: 800,
    color: COLOR_PRIMARY,
  },
  childContainer: {
    padding: 20,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  addButton: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLOR_PRIMARY,
    margin: '10px 5px',
  },
  error: {
    marginLeft: 10,
  },
}));


const DroppableArea = ({
  children, title, addButton, onAddButtonClicked,
  addButtonCaption, error, helperText,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FormControl className={classes.form} error={error}>
        <div className={classes.innerContainer}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>
              {title}
            </Typography>
          </div>
          <div className={classes.childContainer}>
            {children}
          </div>
          {
        addButton
        && (
        <AccentButton
          variant={BUTTON_TYPE_TEXT}
          onClick={onAddButtonClicked}
          caption={addButtonCaption}
          startIcon={<Plus />}
          loading={false}
          className={classes.addButton}
        />
        )
      }
        </div>
        {error && (<FormHelperText className={classes.error}>{helperText}</FormHelperText>)}
      </FormControl>
    </div>
  );
};
export default DroppableArea;

DroppableArea.propTypes = {

  title: PropTypes.string,
  addButtonCaption: PropTypes.string.isRequired,
  helperText: PropTypes.string,

  addButton: PropTypes.bool,
  error: PropTypes.bool,

  onAddButtonClicked: PropTypes.func.isRequired,

  children: PropTypes.node,
};

DroppableArea.defaultProps = {
  title: '',
  helperText: '',

  error: false,
  addButton: false,

  children: (<div />),
};
