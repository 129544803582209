/* eslint-disable react/jsx-indent */
import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    approvalMenu: {
      title: 'Approval',
    },
    approvalPage: {
      title: 'Approval',

      labelNo: 'No',
      labelProcessName: 'Process Name',
      labelApprovalNumber: 'Approval Number',
      labelTitle: 'Title',
      labelRequester: 'Requester',
      labelRequesterId: 'Requester ID',
      labelRequesterName: 'Requester Name',
      labelRequesterNotes: 'Requester Notes',
      labelApprovalStatus: 'Approval Status',
      labelCreatedDate: 'Created Date',
      labelStatusChangedAt: 'Status Changed At',
      labelProviderName: 'Provider Name',
      labelDescription: 'Description',
      labelNotes: 'Notes',
      labelReferenceId: 'Reference ID',
      labelAllowToAddAttachment: 'Allow To Add Attachment',
      labelDisableAppNotification: 'Disable App Notification',
      labelDisableEmailNotification: 'Disable Email Notification',
      labelSteps: 'Steps',
      labelOrder: 'Order',
      labelLabel: 'Label',
      labelStatus: 'Status',
      labelModifiedAt: 'Modified At',
      labelAttachments: 'Attachments',
      labelFileName: 'File Name',
      labelData: 'Data',
      labelFieldName: 'Field Name',
      labelValue: 'Value',
      labelUserId: 'User ID',
      labelFullName: 'Full Name',
      labelStartedAt: 'Started At',
      labelTag: 'Tags',

      placeholderResendEvent: 'Resend Event',

      buttonCaptionApprovers: 'Approvers',
      buttonCaptionComments: 'Comments',
      buttonCaptionResendEvent: 'Resend Event',

      msgConfirmResendEvent: 'Are you sure to resend this event?',
    },
    approvalGroupPage: {
      title: 'Approval Group',

      labelNo: 'No',
      labelName: 'Name',
      labelGroupName: 'Group Name',
      labelDescription: 'Description',
      labelCreatedDate: 'Created Date',
      labelStatus: 'Status',
      labelGroupMembers: 'Group Members',
      labelID: 'ID',
      labelEmail: 'Email',

      buttonCaptionCreateNewApprovalGroup: 'Create New Approval Group',
      buttonCaptionEditApprovalGroup: 'Edit Approval Group',
      buttonCaptionDeleteApprovalGroup: 'Delete Approval Group',
    },
    flowDesignerPage: {
      title: 'Flow Designer',
      titleAddApprover: 'Add Approver',
      titleEditApprover: 'Edit Approver',

      labelStep: 'Step',
      labelNo: 'No',
      labelProcessName: 'Process Name',
      labelDescription: 'Description',
      labelCreatedDate: 'Created Date',
      labelProcessFlow: 'Process Flow',
      labelStepName: 'Step Name',
      labelType: 'Type',
      labelGroupName: 'Group Name',
      labelGroupMode: 'Group Mode',
      labelUser: 'User',
      labelManagerLevel: 'Manager Level',
      labelMaxLevel: 'Max Level',
      labelApproverMode: 'Approver Mode',
      labelApprovers: 'Approvers',
      labelName: 'Name',

      errorProcessFlowMustNotBeEmpty: 'Process Flow must not be empty',
      errorStepName: 'Step Name must not be empty',
      errorType: 'Type must not be empty',
      errorApproverMode: 'Approver Mode must not be empty',
      errorMaxLevel: 'Max Level must not be empty',

      buttonCaptionCreateNewProcess: 'Create New Process',
      buttonCaptionAddProcessFlow: 'Add Process Flow',
      buttonCaptionAddApprover: 'Add Approver',
      buttonCaptionEditFlowDesigner: 'Edit Flow Designer',
      buttonCaptionDeleteFlowDesigner: 'Delete Flow Designer',
      buttonCaptionDeleteProcessFlow: 'Delete Process Flow',
      buttonCaptionTooltipApproverMode: 'If this step has more than one approver:\nAll: All approvers must approve for the process to proceed to the next step\nOne: The process will proceed to the next step if at least one approver approves',
      buttonCaptionTooltipGroupMode: 'SelectAll: All group members will be included as approvers\nSelectOne: Only one group member will be chosen as the approver',
      buttonCaptionRemove: 'Remove',
    },
  },
  id: {
    approvalMenu: {
      title: 'Persetujuan',
    },
    approvalPage: {
      title: 'Persetujuan',

      labelNo: 'No',
      labelProcessName: 'Nama Proses',
      labelApprovalNumber: 'Nomor Persetujuan',
      labelTitle: 'Judul',
      labelRequester: 'Pengaju',
      labelRequesterId: 'ID Pengaju',
      labelRequesterName: 'Nama Pengaju',
      labelRequesterNotes: 'Catatan Pengaju',
      labelApprovalStatus: 'Status Persetujuan',
      labelCreatedDate: 'Tanggal Dibuat',
      labelStatusChangedAt: 'Status Berubah Pada',
      labelProviderName: 'Nama Penyedia',
      labelDescription: 'Deskripsi',
      labelNotes: 'Catatan',
      labelReferenceId: 'ID Referensi',
      labelAllowToAddAttachment: 'Izinkan untuk Menambahkan Lampiran',
      labelDisableAppNotification: 'Matikan Notifikasi Aplikasi',
      labelDisableEmailNotification: 'Matikan Notifikasi Email',
      labelSteps: 'Langkah',
      labelOrder: 'Urutan',
      labelLabel: 'Label',
      labelStatus: 'Status',
      labelModifiedAt: 'Diubah Pada',
      labelAttachments: 'Lampiran',
      labelFileName: 'Nama File',
      labelData: 'Data',
      labelFieldName: 'Nama Field',
      labelValue: 'Nilai',
      labelUserId: 'ID Pengguna',
      labelFullName: 'Nama Lengkap',
      labelStartedAt: 'Dimulai pada',
      labelTag: 'Penanda',

      placeholderResendEvent: 'Kirim Ulang Event',

      buttonCaptionApprovers: 'Penyetuju',
      buttonCaptionComments: 'Komentar',
      buttonCaptionResendEvent: 'Kirim Ulang Event',

      msgConfirmResendEvent: 'Apakah Anda yakin untuk mengirim ulang event ini?',
    },
    approvalGroupPage: {
      title: 'Grup Persetujuan',

      labelNo: 'No',
      labelName: 'Nama',
      labelGroupName: 'Nama Grup',
      labelDescription: 'Deskripsi',
      labelCreatedDate: 'Tanggal Dibuat',
      labelStatus: 'Status',
      labelGroupMembers: 'Anggota Grup',
      labelID: 'ID',
      labelEmail: 'Email',

      buttonCaptionCreateNewApprovalGroup: 'Buat Grup Persetujuan Baru',
      buttonCaptionEditApprovalGroup: 'Ubah Grup Persetujuan',
      buttonCaptionDeleteApprovalGroup: 'Hapus Grup Persetujuan',
    },
    flowDesignerPage: {
      title: 'Perancang Alur',
      titleAddApprover: 'Tambah Data Penyetuju',
      titleEditApprover: 'Ubah Data Penyetuju',

      labelStep: 'Langkah',
      labelNo: 'No',
      labelProcessName: 'Nama Proses',
      labelDescription: 'Deskripsi',
      labelCreatedDate: 'Tanggal Dibuat',
      labelProcessFlow: 'Alur Proses',
      labelStepName: 'Nama Langkah',
      labelType: 'Tipe',
      labelGroupName: 'Nama Grup',
      labelGroupMode: 'Mode Grup',
      labelUser: 'Pengguna',
      labelManagerLevel: 'Tingkat Manager',
      labelMaxLevel: 'Tingkat Maksimum',
      labelApproverMode: 'Mode Penyetuju',
      labelApprovers: 'Penyetuju',
      labelName: 'Nama',

      errorProcessFlowMustNotBeEmpty: 'Alur Proses tidak boleh kosong',
      errorStepName: 'Nama Langkah tidak boleh kosong',
      errorType: 'Tipe tidak boleh kosong',
      errorApproverMode: 'Mode Penyetuju tidak boleh kosong',
      errorMaxLevel: 'Tingkat Maksimum tidak boleh kosong',

      buttonCaptionCreateNewProcess: 'Buat Proses Baru',
      buttonCaptionAddProcessFlow: 'Tambah Alur Proses',
      buttonCaptionAddApprover: 'Tambah Penyetuju',
      buttonCaptionEditFlowDesigner: 'Ubah Perancang Alur',
      buttonCaptionDeleteFlowDesigner: 'Hapus Perancang Alur',
      buttonCaptionDeleteProcessFlow: 'Hapus Alur Proses',
      buttonCaptionTooltipApproverMode: 'Jika langkah ini memiliki lebih dari satu pemberi persetujuan:\nAll: Semua pemberi persetujuan harus menyetujui agar proses dapat berlanjut ke langkah berikutnya\nOne: Proses akan berlanjut ke langkah berikutnya jika setidaknya satu pemberi persetujuan menyetujui',
      buttonCaptionTooltipGroupMode: 'SelectAll: Semua anggota grup akan disertakan sebagai pemberi persetujuan\nSelectOne: Hanya satu anggota grup yang akan dipilih sebagai pemberi persetujuan',
      buttonCaptionRemove: 'Hapus',
    },
  },
});

export default LocalizedString;
