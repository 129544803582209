import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import LocalizedString from '../../localization';
import { logoutAsync, setAlertErrorMessage, setDroppableFieldField } from '../../redux/action';
import { downloadMyConfigItemsAsync } from '../../module/configuration/redux/action';
import DraggableField from './draggable-field.presentation';

const mapStateToProps = (state) => ({
  selectedType: Object.values(state.uiFlowDesigner.selectedType),
  myConfigItems: state.myConfigItems,
  pageMode: state.uiFunctionalPage.pageMode,
  selectedProcessFlowIndex: state.uiFlowDesigner.selectedProcessFlowIndex,
});

const mapDispatchToProps = (dispatch) => ({
  onEndDrag: (fields) => {
    dispatch(setDroppableFieldField(fields));
  },
  onAlertConfirm: async (message, history, myConfigItems, onConfirmPressed, index) => {
    if (message === LocalizedString.common.msgLogoutConfirmation) {
      if (isEmpty(myConfigItems)) {
        try {
          await dispatch(downloadMyConfigItemsAsync());
        } catch (error) {
          dispatch(setAlertErrorMessage(error));
        }
      }
      dispatch(logoutAsync(history.push, message))
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    } else {
      onConfirmPressed(index);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DraggableField);
