import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Checkbox, Divider, makeStyles, Typography,
} from '@material-ui/core';
import { CheckBox } from '@material-ui/icons';
import {
  CERTIFICATION_STATUS_NEED, CERTIFICATION_STATUS_NO_NEED, COLOR_BODY_TEXT_LIGHT, COLOR_PRIMARY,
  COLOR_SECONDARY, RXFIELD_ASSET_SCREENING_CERTIFICATION_TYPE, RXFORM_ASSET_SCREENING,
} from '../../../constant';
import { EditableTableField, StyledDialog } from '../../../component';
import GlobalLocalizedString from '../../../../../localization';
import LocalizedString from '../../../localization';
import { renderReduxFormOutlinedDropdownTextField } from '../../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../type';
import { rxformValidateAssetScreening } from '../../../validation';

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: COLOR_SECONDARY,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  greyText: {
    color: COLOR_BODY_TEXT_LIGHT,
    fontWeight: 'bold',
  },
}));

const renderField = (initialValues, certificationTypes, adding, loadingCertificationTypes,
  onCertTypeOptionSelected, onChangeCertTypeText, dialogMode, classes) => {
  switch (dialogMode) {
    case CERTIFICATION_STATUS_NEED:
      return (
        <div style={{ flex: 1 }}>
          <Field
            name={RXFIELD_ASSET_SCREENING_CERTIFICATION_TYPE}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.assetScreeningPage.labelType}
            label={LocalizedString.assetScreeningPage.labelType}
            disabled={adding}
            data={certificationTypes}
            value={initialValues[RXFIELD_ASSET_SCREENING_CERTIFICATION_TYPE]}
            loading={loadingCertificationTypes}
            onChangeText={onChangeCertTypeText}
            onOptionSelected={onCertTypeOptionSelected}
            required
          />
        </div>
      );
    case CERTIFICATION_STATUS_NO_NEED:
      return (
        <div className={classes.rowContainer} style={{ flex: 1 }}>
          <Checkbox
            checkedIcon={<CheckBox fontSize="small" />}
            style={{ marginRight: 8, color: COLOR_PRIMARY }}
            checked
            disabled
          />
          <Typography variant="caption" className={classes.text}>
            {LocalizedString.assetScreeningPage.labelTheseAssets}
          </Typography>
        </div>
      );
    default: return null;
  }
};
const AssetScreeningConfirmationDialog = ({
  initialValues, certificationTypes, selectedAssets,
  adding, dialogVisibility, loadingCertificationTypes,
  handleSubmit, onAppear, onCancelPressed, onCertTypeOptionSelected, onChangeCertTypeText,
  onSubmitPressed, setSelectedRows,
  dialogMode,
}) => {
  const classes = useStyles();

  useEffect(() => onAppear(dialogMode), [onAppear, dialogMode]);

  return (
    <StyledDialog
      submitting={adding}
      visibility={dialogVisibility && (dialogMode === CERTIFICATION_STATUS_NEED
        || dialogMode === CERTIFICATION_STATUS_NO_NEED)}
      onCancelPressed={onCancelPressed}
      onSubmitPressed={handleSubmit(() => onSubmitPressed(selectedAssets, setSelectedRows))}
      submitButtonCaption={GlobalLocalizedString.common.buttonCaptionSubmit}
      title={dialogMode === CERTIFICATION_STATUS_NEED
        ? LocalizedString.assetScreeningPage.titleNeedCertification
        : LocalizedString.assetScreeningPage.titleNoNeedCertification}
    >
      <Divider className={classes.divider} />

      <div className={classes.rowContainer}>
        {renderField(initialValues, certificationTypes, adding, loadingCertificationTypes,
          onCertTypeOptionSelected, onChangeCertTypeText, dialogMode, classes)}
        <Typography variant="body1" className={classes.greyText}>
          {`${LocalizedString.assetScreeningPage.labelTotalAssets} ${selectedAssets.length}`}
        </Typography>
      </div>

      <EditableTableField
        data={selectedAssets}
        tableColumns={[
          { title: LocalizedString.assetPage.labelId, field: 'sourceId', sorting: false },
          { title: LocalizedString.assetPage.labelAssetDescription, field: 'assetDescription', sorting: false },
          { title: LocalizedString.assetPage.labelPartCode, field: 'partCode', sorting: false },
          { title: LocalizedString.assetPage.labelSerialNumber, field: 'serialNumber', sorting: false },
          { title: LocalizedString.assetPage.labelAssetNumber, field: 'assetNumber', sorting: false },
          { title: LocalizedString.assetPage.labelAssetClass, field: 'className', sorting: false },
        ]}
        disabled
        disableToolbar
        paging
      />
    </StyledDialog>
  );
};

export default reduxForm({
  form: RXFORM_ASSET_SCREENING,
  validate: rxformValidateAssetScreening,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AssetScreeningConfirmationDialog);

AssetScreeningConfirmationDialog.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  certificationTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  selectedAssets: PropTypes.arrayOf(PropTypes.string).isRequired,
  adding: PropTypes.bool.isRequired,
  dialogVisibility: PropTypes.bool.isRequired,
  loadingCertificationTypes: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCertTypeOptionSelected: PropTypes.func.isRequired,
  onChangeCertTypeText: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  dialogMode: PropTypes.string.isRequired,
};
