import { reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  addingEditingFlowDesigner, clearFlowDesigners, setAlertErrorMessage,
  setFunctionalPageMode, clearFlowDesignerApprovers,
} from '../simple-action';
import {
  PAGE_MODE_TABLE, RXFORM_FLOW_DESIGNER, TYPE_APPROVER_TABLE_GROUP,
  TYPE_APPROVER_TABLE_MANAGER_LEVEL, TYPE_APPROVER_TABLE_USER, TYPE_DIRECT_MANAGER, TYPE_REGULAR,
} from '../../../constant';
import downloadFlowDesignersAsync from './downloadFlowDesignersAsync';
import { addFlowDesigner, editFlowDesigner, toNumber } from '../../../helper';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingFlowDesigner(true));

    const { token } = getState().authentication;
    const { tappedId, approvers } = getState().uiFlowDesigner;

    const arrayedApprover = !isEmpty(Object.values(approvers))
      ? Object.values(approvers)
      : [];

    const getApprovers = (type, id) => {
      if (arrayedApprover.length > 0 && type === TYPE_REGULAR) {
        const findIndex = arrayedApprover.findIndex((x) => x.stepId === id);
        const { data } = arrayedApprover[findIndex];

        if (data) {
          const revisedApproverBody = () => data.map((x) => {
            switch (x?.data?.type) {
              case TYPE_APPROVER_TABLE_MANAGER_LEVEL:
                return ({
                  type: x?.data?.type,
                  group: x?.data?.group,
                  user: x?.data?.user,
                  managerLevel: toNumber(x?.data?.managerLevel),
                  groupMode: x?.data?.groupMode,
                });
              case TYPE_APPROVER_TABLE_USER:
                return ({
                  type: x?.data?.type,
                  group: x?.data?.group,
                  userId: x?.data?.user?.id,
                  managerLevel: toNumber(x?.data?.managerLevel),
                  groupMode: x?.data?.groupMode,
                });
              case TYPE_APPROVER_TABLE_GROUP:
                return ({
                  type: x?.data?.type,
                  groupId: x?.data?.group?.id,
                  user: x?.data?.user,
                  managerLevel: toNumber(x?.data?.managerLevel),
                  groupMode: x?.data?.groupMode,
                });
              default: return ({});
            }
          });
          return revisedApproverBody();
        }
      }
      return [];
    };

    const { processName, description, workflow } = values;

    const body = {
      processName,
      description,
      workflow: workflow.map((x, index) => ({
        type: x?.type,
        stepName: x?.stepName,
        order: index + 1,
        maxLevel: (x?.type === TYPE_DIRECT_MANAGER && x?.maxLevel) ? toNumber(x?.maxLevel) : null,
        approverMode: (x?.type === TYPE_REGULAR && x?.approverMode) ? x?.approverMode : null,
        approvers: getApprovers(x?.type, x?.id),
      })),
    };

    if (tappedId) {
      const newBody = {
        id: tappedId,
        ...body,
        token,
      };
      await editFlowDesigner(newBody, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addFlowDesigner(body, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    }

    dispatch(reset(RXFORM_FLOW_DESIGNER));
    dispatch(clearFlowDesigners());
    dispatch(downloadFlowDesignersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(clearFlowDesignerApprovers());
  } finally {
    dispatch(addingEditingFlowDesigner(false));
  }
};
