import {
  SET_FLOW_DESIGNER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_FLOW_DESIGNER_SEARCH_TEXT, SET_FLOW_DESIGNER_SELECTED_ORDER_BY,
  SET_FLOW_DESIGNER_SELECTED_PAGE_SIZE, SET_FLOW_DESIGNER_TAPPED_ID,
  ADDING_EDITING_FLOW_DESIGNER, DOWNLOADING_FLOW_DESIGNERS,
  DOWNLOADING_DELETING_FLOW_DESIGNER, SET_FLOW_DESIGNER_SELECTED_TYPE,
  SET_FLOW_DESIGNER_SELECTED_APPROVER_MODE, SET_FLOW_DESIGNER_APPROVERS,
  SET_FLOW_DESIGNER_APPROVERS_DIALOG_VISIBILITY, SET_FLOW_DESIGNER_ADDING_APPROVERS,
  SET_FLOW_DESIGNER_SELECTED_APPROVER_INDEX, CLEAR_FLOW_DESIGNER_APPROVERS,
  SET_FLOW_DESIGNER_SELECTED_PROCESS_FLOW_INDEX,
} from '../action';
import { INITIAL_ORDER_BY_FLOW_DESIGNERS } from '../../constant';

const initialState = {
  downloading: false,
  addingApprovers: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_FLOW_DESIGNERS,
  filterString: '',
  selectedType: {},
  approvers: {},
  selectedApproverMode: '',
  selectedApproverIndex: '',
  selectedProcessFlowIndex: '',
  addingEditingFlowDesigner: false,
  downloadDeletingFlowDesigner: false,
  approverDialogVisibility: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_FLOW_DESIGNERS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_FLOW_DESIGNER:
      return { ...state, addingEditingFlowDesigner: action.status };
    case DOWNLOADING_DELETING_FLOW_DESIGNER:
      return { ...state, downloadDeletingFlowDesigner: action.status };
    case SET_FLOW_DESIGNER_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_FLOW_DESIGNER_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_FLOW_DESIGNER_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_FLOW_DESIGNER_SELECTED_TYPE: {
      return {
        ...state,
        selectedType: { ...state.approvers, [action.option.workflow]: action.option.value },
      };
    }
    case SET_FLOW_DESIGNER_APPROVERS: {
      return {
        ...state,
        approvers: { ...state.approvers, [action.approver.stepId]: action.approver },
      };
    }
    case CLEAR_FLOW_DESIGNER_APPROVERS: {
      return {
        ...state,
        approvers: initialState.approvers,
      };
    }
    case SET_FLOW_DESIGNER_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_FLOW_DESIGNER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_FLOW_DESIGNER_SELECTED_APPROVER_MODE:
      return { ...state, approverMode: action.option };
    case SET_FLOW_DESIGNER_SELECTED_APPROVER_INDEX:
      return { ...state, selectedApproverIndex: action.option };
    case SET_FLOW_DESIGNER_APPROVERS_DIALOG_VISIBILITY:
      return { ...state, approverDialogVisibility: action.option };
    case SET_FLOW_DESIGNER_ADDING_APPROVERS:
      return { ...state, addingApprovers: action.option };
    case SET_FLOW_DESIGNER_SELECTED_PROCESS_FLOW_INDEX:
      return { ...state, selectedProcessFlowIndex: action.index };
    default: return state;
  }
};
