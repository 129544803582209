import { reset } from 'redux-form';
import uuid from 'uuid';
import {
  addApproverTableData,
  setAddingApproverTable,
  setApproverTableSelectedGroupMode,
  setApproverTableSelectedGroupName,
  setApproverTableSelectedLevel,
  setApproverTableSelectedTableDataId,
  setApproverTableSelectedType,
  setApproverTableSelectedUser,
} from '../simple-action';
import {
  RXFORM_APPROVER_TABLE, TYPE_APPROVER_TABLE_GROUP,
  TYPE_APPROVER_TABLE_MANAGER_LEVEL, TYPE_APPROVER_TABLE_USER,
} from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(setAddingApproverTable(true));

    const {
      selectedType,
      selectedUser,
      selectedGroupName,
      selectedGroupMode,
      selectedLevel,
      selectedTableDataId,
    } = getState().uiApproverTable;

    const body = () => {
      switch (selectedType) {
        case TYPE_APPROVER_TABLE_USER:
          return ({
            data: {
              type: selectedType,
              group: null,
              user: {
                id: selectedUser.value,
                fullName: selectedUser.label,
              },
              managerLevel: null,
              groupMode: null,
            },
            id: selectedTableDataId || uuid(),
          });

        case TYPE_APPROVER_TABLE_MANAGER_LEVEL:
          return ({
            data: {
              type: selectedType,
              group: null,
              user: null,
              managerLevel: selectedLevel,
              groupMode: null,
            },
            id: selectedTableDataId || uuid(),
          });

        case TYPE_APPROVER_TABLE_GROUP:
          return ({
            data: {
              type: selectedType,
              group: {
                id: selectedGroupName.value,
                name: selectedGroupName.label,
              },
              user: null,
              managerLevel: null,
              groupMode: selectedGroupMode,
            },
            id: selectedTableDataId || uuid(),
          });

        default: return ({
          data: null,
        });
      }
    };

    dispatch(addApproverTableData(body()));
    dispatch(setApproverTableSelectedType(''));
    dispatch(setApproverTableSelectedGroupMode(''));
    dispatch(setApproverTableSelectedGroupName(''));
    dispatch(setApproverTableSelectedLevel(''));
    dispatch(setApproverTableSelectedUser(''));
    dispatch(setApproverTableSelectedTableDataId(''));
  } finally {
    dispatch(reset(RXFORM_APPROVER_TABLE));
    dispatch(setAddingApproverTable(false));
  }
};
