import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';
import { Field, reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core';
import { AccentButton, StyledDialog } from '../../../../component';
import {
  RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_TYPE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_USER,
  RXFIELD_FLOW_DESIGNER_APPROVERS, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_LEVEL,
  RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_MODE, RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_NAME,
  RXFORM_APPROVER_TABLE, TYPE_APPROVER_TABLE_DROPDOWN, TYPE_APPROVER_TABLE_USER,
  TYPE_APPROVER_TABLE_MANAGER_LEVEL, TYPE_APPROVER_TABLE_GROUP,
  APPROVER_TABLE_GROUP_MODE, PAGE_MODE_EDIT,
} from '../../constant';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import {
  renderReduxFormSimpleDropdownField, renderReduxFormEditableTableField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../type';
import { rxformValidateApprovalTable } from '../../validation';

const useStyles = makeStyles(() => ({
  formContentField: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginBottom: 10,
  },
  formContainer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
  },
  mb10: {
    marginBottom: 10,
  },
  mr10: {
    marginRight: 10,
  },
}));

const renderFields = (
  type, classes,
  users, loadingUser, onChangeUserText, onUserOptionSelected,
  groupName, loadingGroupName, onChangeGroupNameText, onGroupNameOptionSelected,
  onChangeManagerLevelText, onGroupModeOptionSelected,
) => {
  switch (type) {
    case TYPE_APPROVER_TABLE_USER:
      return (
        <div className={classes.formContentField}>
          <Field
            name={RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_USER}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.flowDesignerPage.labelUser}
            label={LocalizedString.flowDesignerPage.labelUser}
            disabled={false}
            data={users}
            value=""
            loading={loadingUser}
            onChangeText={onChangeUserText}
            onOptionSelected={(option) => onUserOptionSelected(option)}
            required
          />
        </div>
      );
    case TYPE_APPROVER_TABLE_MANAGER_LEVEL:
      return (
        <div className={classes.formContentField}>
          <Field
            name={RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_LEVEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.flowDesignerPage.labelManagerLevel}
            label={LocalizedString.flowDesignerPage.labelManagerLevel}
            disabled={false}
            onChange={(text) => onChangeManagerLevelText(text)}
            type="number"
            required
          />
        </div>
      );
    case TYPE_APPROVER_TABLE_GROUP:
      return (
        <div className={classes.formContentField}>
          <Field
            name={RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_NAME}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.flowDesignerPage.labelGroupName}
            label={LocalizedString.flowDesignerPage.labelGroupName}
            disabled={false}
            data={groupName}
            value=""
            loading={loadingGroupName}
            onChangeText={onChangeGroupNameText}
            onOptionSelected={(option) => onGroupNameOptionSelected(option)}
            required
          />
          <Field
            name={RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_GROUP_MODE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.flowDesignerPage.labelGroupMode}
            label={LocalizedString.flowDesignerPage.labelGroupMode}
            disabled={false}
            data={APPROVER_TABLE_GROUP_MODE}
            onOptionSelected={(option) => {
              onGroupModeOptionSelected(option);
            }}
            required
            tooltipTitle={LocalizedString.flowDesignerPage.buttonCaptionTooltipGroupMode}
            useTooltip
          />
        </div>
      );
    default: return null;
  }
};

const AddApproversDialog = ({
  approverTable, addingTable,
  adding, dialogVisibility, downloading, onCancelPressed,
  onAppear, onCancelAddPressed, onTypeOptionSelected, selectedType,
  onSubmitPressed, onAddTablePressed, onSaveAddApproverPressed,
  users, loadingUser, onChangeUserText, onUserOptionSelected,
  groupName, loadingGroupName, onChangeGroupNameText, onGroupNameOptionSelected,
  onGroupModeOptionSelected, onChangeManagerLevelText, stepId,
  onEditTablePressed, onDeleteTablePressed, disableSave, pageMode,
}) => {
  const classes = useStyles();

  useEffect(() => onAppear(), [onAppear, adding]);

  const renderBody = () => {
    if (adding) {
      return (
        <div className={classes.formContainer}>
          <Field
            name={RXFIELD_FLOW_DESIGNER_APPROVER_TABLE_TYPE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.flowDesignerPage.labelType}
            label={LocalizedString.flowDesignerPage.labelType}
            disabled={false}
            data={TYPE_APPROVER_TABLE_DROPDOWN}
            onOptionSelected={(option) => {
              onTypeOptionSelected(option);
            }}
            required
          />
          {renderFields(
            selectedType, classes,
            users, loadingUser, onChangeUserText, onUserOptionSelected,
            groupName, loadingGroupName, onChangeGroupNameText, onGroupNameOptionSelected,
            onChangeManagerLevelText, onGroupModeOptionSelected,
          )}
          <div className={classes.buttonContainer}>
            <AccentButton
              onClick={onCancelAddPressed}
              variant="outlined"
              caption={GlobalLocalizedString.common.buttonCaptionCancel}
              disabled={downloading}
              size="small"
              style={classes.mr10}
            />
            <AccentButton
              onClick={() => onSaveAddApproverPressed()}
              variant="contained"
              caption={GlobalLocalizedString.common.buttonCaptionSave}
              disabled={disableSave}
              size="small"
            />
          </div>
        </div>
      );
    }
    return (
      <div>
        <AccentButton
          onClick={onAddTablePressed}
          variant="contained"
          caption={LocalizedString.flowDesignerPage.buttonCaptionAddApprover}
          disabled={downloading}
          endIcon={<Add />}
          style={classes.mb10}
        />
        <Field
          name={RXFIELD_FLOW_DESIGNER_APPROVERS}
          component={renderReduxFormEditableTableField}
          data={approverTable}
          tableColumns={[
            {
              title: LocalizedString.flowDesignerPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
            },
            { title: LocalizedString.flowDesignerPage.labelName, field: 'approverTableUserName', sorting: false },
            {
              title: LocalizedString.flowDesignerPage.labelType, field: 'approverTableType', sorting: false,
            },
            { title: LocalizedString.flowDesignerPage.labelGroupMode, field: 'approverTableGroupMode', sorting: false },
            { title: LocalizedString.flowDesignerPage.labelManagerLevel, field: 'approverTableManagerLevel', sorting: false },
          ]}
          onEditPressed={(e, data) => {
            onEditTablePressed(data.id, data.data);
          }}
          onDeletePressed={(e, data) => onDeleteTablePressed(approverTable, data)}
          disableToolbar
        />
      </div>
    );
  };

  return (
    <StyledDialog
      disableSubmitButton={!approverTable.length || addingTable}
      submitting={addingTable}
      visibility={dialogVisibility}
      onCancelPressed={adding ? onCancelAddPressed : onCancelPressed}
      onSubmitPressed={() => onSubmitPressed(approverTable, stepId)}
      title={pageMode === PAGE_MODE_EDIT
        ? LocalizedString.flowDesignerPage.titleEditApprover
        : LocalizedString.flowDesignerPage.titleAddApprover}
      submitButtonCaption={
        GlobalLocalizedString.common.buttonCaptionSave
      }
      hideButton={adding}
    >
      {renderBody()}
    </StyledDialog>
  );
};

export default reduxForm({
  form: RXFORM_APPROVER_TABLE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxformValidateApprovalTable,
})(AddApproversDialog);

AddApproversDialog.propTypes = {
  approverTable: PropTypes.arrayOf(PropTypes.object).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  groupName: PropTypes.arrayOf(SimpleDataShape).isRequired,

  adding: PropTypes.bool.isRequired,
  addingTable: PropTypes.bool.isRequired,
  dialogVisibility: PropTypes.bool,
  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  loadingGroupName: PropTypes.bool.isRequired,
  disableSave: PropTypes.bool.isRequired,

  selectedType: PropTypes.string.isRequired,
  stepId: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,

  onSaveAddApproverPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAddTablePressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onTypeOptionSelected: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func,
  onCancelAddPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onUserOptionSelected: PropTypes.func.isRequired,
  onChangeGroupNameText: PropTypes.func.isRequired,
  onChangeManagerLevelText: PropTypes.func.isRequired,
  onGroupNameOptionSelected: PropTypes.func.isRequired,
  onGroupModeOptionSelected: PropTypes.func.isRequired,
  onEditTablePressed: PropTypes.func.isRequired,
  onDeleteTablePressed: PropTypes.func.isRequired,
};

AddApproversDialog.defaultProps = {
  dialogVisibility: false,
  onCancelPressed: () => {},
};
